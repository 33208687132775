import { FC } from 'react';

import { useAppSelector } from '../../Redux/store';
import { IMomentum } from '../../Types';

interface IProps {
  momentum?: IMomentum;
  descending?: boolean;
  max?: number;
}

const MomentumBar: FC<IProps> = ({ momentum, descending, max }) => {
  const identity = useAppSelector(state => state.login.identity);
  const awayId = useAppSelector(state => state.match.value.awayTeam.id);

  const getHeight = () => {
    if (momentum && max) {
      return (Math.abs(momentum.momentum) / max) * 50;
    }
    return 0;
  };

  const getTeam = () => {
    if (momentum) {
      if (momentum?.momentum > 0) {
        return identity.teamId === awayId ? 'away' : 'home';
      } else {
        return identity.teamId === awayId ? 'home' : 'away';
      }
    }
  };

  return (
    <div className='MomentumBar'>
      {momentum && (
        <div
          style={{ height: `${getHeight()}%` }}
          className={`MomentumBar-inner${descending ? ' descending' : ''} ${getTeam()}`}
        ></div>
      )}
    </div>
  );
};

export default MomentumBar;
