import { FC, useEffect, useRef, useState } from 'react';

import { WhistleGrayIcon, WhistleGreenIcon, WhistleRedIcon } from '../../Icons';
import { useAppSelector } from '../../Redux/store';
import { IGameState, IIceTimeSort } from '../../Types';
import {
  filterGameStates,
  getColorOnTimeline,
  getLabelByValue,
  getPositionOnTimeline,
  getThirdsFilters,
  getTimeline,
} from '../../Utils';

interface IProps {
  gameStates?: IGameState[];
  hasScrollbar: boolean;
  setSortBy?: (value: IIceTimeSort) => void;
  sortBy?: IIceTimeSort;
  thirdsActive: string;
}

const IceTimeTimeline: FC<IProps> = ({
  gameStates,
  hasScrollbar,
  setSortBy,
  sortBy,
  thirdsActive,
}) => {
  const match = useAppSelector(state => state.match.value);
  const [timelineWidth, setTimelineWidth] = useState<number>(0);
  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timelineRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (timelineRef.current!.offsetWidth !== timelineWidth) {
          setTimelineWidth(timelineRef.current!.offsetWidth);
        }
      });

      resizeObserver.observe(timelineRef.current);

      return function cleanup() {
        resizeObserver.disconnect();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRef.current]);

  const filteredGameStates = gameStates && gameStates.filter(filterGameStates);

  return (
    <div
      className={`IceTimeTimeline${
        filteredGameStates !== undefined ? ' top' : ''
      }${hasScrollbar ? ' scrollbar' : ''}`}
    >
      <div className='IceTimeTimeline-labels'>
        {filteredGameStates !== undefined && setSortBy && (
          <>
            <p
              onClick={() => setSortBy('lineup')}
              className={sortBy === 'lineup' ? 'active' : undefined}
            >
              Sestava
            </p>
            <p onClick={() => setSortBy('TOI')} className={sortBy === 'TOI' ? 'active' : undefined}>
              TOI
            </p>
            <div className='IceTimeTimeline-labels--thirdStats'>
              <p className='disclaimer'>
                {
                  getLabelByValue(getThirdsFilters(match.length, match.game, true), thirdsActive)
                    ?.label
                }
              </p>
              <p
                onClick={() => setSortBy('TOT')}
                className={sortBy === 'TOT' ? 'active' : undefined}
              >
                TOT
              </p>
              <p onClick={() => setSortBy('PP')} className={sortBy === 'PP' ? 'active' : undefined}>
                PP
              </p>
              <p onClick={() => setSortBy('SH')} className={sortBy === 'SH' ? 'active' : undefined}>
                SH
              </p>
            </div>
          </>
        )}
      </div>
      <div className='IceTimeTimeline-time' ref={timelineRef}>
        {getTimeline(thirdsActive).map(timeStamp => (
          <span key={timeStamp} className='IceTimeTimeline-timeStamp'>
            {timeStamp}
          </span>
        ))}
        {filteredGameStates &&
          filteredGameStates.map((state, index) => {
            return (
              <div
                key={index}
                className='IceTimeTimeline-state'
                style={{
                  ...getPositionOnTimeline(state, timelineWidth, 18),
                  ...getColorOnTimeline(state),
                }}
              >
                <div className='IceTimeTimeline-iconContainer'>
                  {state.type === 'PP' && <WhistleGreenIcon />}
                  {state.type === 'SH' && <WhistleRedIcon />}
                  {state.type === 'RG' && state.state !== '5:5' && <WhistleGrayIcon />}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default IceTimeTimeline;
