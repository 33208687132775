import { TAuthResponse } from '../Types/login';
import { axiosInstance } from './axiosInstance';

export const auth = async (email: string, password: string) => {
  const body = {
    grant_type: 'client_credentials',
    client_id: email,
    client_secret: password,
  };
  const response: TAuthResponse = await axiosInstance.post('/token', body);
  return response;
};
