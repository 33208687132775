//Redux
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

//Types
import { IMatch, IOvertimeInfo, ITeam, IVideoStatus } from '../Types';

const teamInitialState: ITeam = {
  id: '',
  name: '',
  shortName: '',
  shortcut: '',
  gameState: [],
  roster: [],
  score: 0,
};

const matchInitialState: IMatch = {
  awayTeam: teamInitialState,
  homeTeam: teamInitialState,
  faceOff: [],
  id: '',
  lastModification: '',
  lastSynchronisation: '',
  league: '',
  length: 0,
  round: '',
  season: '',
  shot: [],
  goal: [],
  game: {} as IOvertimeInfo,
  momentum: [],
  videoStatus: IVideoStatus.end,
};

interface IState {
  value: IMatch;
}

const initialState: IState = {
  value: matchInitialState,
};

export const matchSlice = createSlice({
  name: 'match',
  initialState: initialState,
  reducers: {
    setMatch: (state, action: PayloadAction<IMatch>) => {
      state.value = action.payload;
    },
  },
});

export const { setMatch } = matchSlice.actions;
export default matchSlice.reducer;
