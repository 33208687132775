import { FC } from 'react';

import { useVideo } from '../../Hooks/useVideo';
import { IVideo } from '../../Types';
import VideoFrame from './VideoFrame';

interface IProps {
  video: IVideo;
}

const VideoFrameWrapper: FC<IProps> = ({ video }) => {
  const { html, key } = useVideo(video);

  return <VideoFrame html={html} key={key} />;
};

export default VideoFrameWrapper;
