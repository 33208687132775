import { FC } from 'react';

interface IProps {
  title: string;
  color: 'black' | 'grey' | 'main' | 'background' | 'loginBlue' | 'white';
  type: 'contained' | 'outlined';
  disabled?: boolean;
  maxWidth?: number;
  padding?: number;
  fontSize?: number;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const Buttons: FC<IProps> = ({
  title,
  color,
  type,
  disabled,
  maxWidth,
  padding = 6,
  fontSize = 12,
  onClick,
  icon,
}) => {
  return (
    <div
      className={`Buttons ${color} ${type}${disabled ? ' disabled' : ''}`}
      style={{
        maxWidth: maxWidth ? maxWidth : 'unset',
        padding: padding,
      }}
      onClick={onClick}
    >
      {icon}
      <p style={{ fontSize: fontSize }}>{title}</p>
    </div>
  );
};

export default Buttons;
