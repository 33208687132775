import { useEffect, useState } from 'react';

import { getFormations } from '../Api';
import { useAppSelector } from '../Redux/store';
import { IFormations } from '../Types';

export const useGetFormations = (matchId: string) => {
  const token = useAppSelector(state => state.login.token.access_token);
  const [formations, setFormations] = useState<IFormations>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token) handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, matchId]);

  const handleLoadData = () => {
    setIsLoading(true);
    getFormations(token, matchId)
      .then(res => {
        setFormations(res.data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response.status !== 404) {
          // 404 means that match is not active now
          console.log('Get match detail err:', err);
        }
      });
  };

  return { formations, isLoading, handleLoadData };
};
