import { Dialog } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PlayerPickerRow from '../03-Partials/PlayerPickerRow';
import Buttons from '../04-Micros/Buttons';
import Filter from '../04-Micros/Filter';
import { IPlayerPickerItemProps } from '../04-Micros/PlayerPickerItem';
import {
  addAwayPending,
  addHomePending,
  removeAwayPending,
  removeHomePending,
} from '../../Redux/pickedPlayers';
import { useAppSelector } from '../../Redux/store';
import { IPlayer } from '../../Types';
import {
  getSortedPlayerItemsByNumber,
  sortPlayersByLine,
  transformPlayersToPlayerItems,
} from '../../Utils';

interface IProps {
  open: boolean;
  activeTeam: string;
  homeShortcut: string;
  awayShortcut: string;
  data: IPlayer[];
  pickedPlayers: IPlayer[];
  onClose: () => void;
  onPlayersPicked: (players: IPlayer[]) => void;
  onActiveTeamChange: (team: string) => void;
}

const PlayerPickerModal: FC<IProps> = ({
  open,
  activeTeam,
  homeShortcut,
  awayShortcut,
  data,
  pickedPlayers,
  onActiveTeamChange,
  onClose,
  onPlayersPicked,
}) => {
  const dispatch = useDispatch();
  const pickedPlayersSelector = useAppSelector(state => state.pickedPlayers.value);
  const [players, setPlayers] = useState<IPlayer[]>(sortPlayersByLine(data));
  const [sortedPlayers, setSortedPlayers] = useState({
    forwards: getSortedPlayerItemsByNumber(
      transformPlayersToPlayerItems(
        [...pickedPlayersSelector.homePending, ...pickedPlayersSelector.awayPending],
        players,
        'forward',
      ),
      3,
    ),
    backwards: getSortedPlayerItemsByNumber(
      transformPlayersToPlayerItems(
        [...pickedPlayersSelector.homePending, ...pickedPlayersSelector.awayPending],
        players,
        'backward',
      ),
      2,
    ),
  });

  useEffect(() => {
    let sortedPlayersByLine = sortPlayersByLine(data);
    setPlayers(sortedPlayersByLine);
    setSortedPlayers({
      forwards: getSortedPlayerItemsByNumber(
        transformPlayersToPlayerItems(
          [...pickedPlayersSelector.homePending, ...pickedPlayersSelector.awayPending],
          sortedPlayersByLine,
          'forward',
        ),
        3,
      ),
      backwards: getSortedPlayerItemsByNumber(
        transformPlayersToPlayerItems(
          [...pickedPlayersSelector.homePending, ...pickedPlayersSelector.awayPending],
          sortedPlayersByLine,
          'backward',
        ),
        2,
      ),
    });
  }, [data, pickedPlayers, pickedPlayersSelector.homePending, pickedPlayersSelector.awayPending]);

  const handleRowClick = (players: IPlayerPickerItemProps[]) => {
    let deactivateAll = players.find(player => !player.active) === undefined;
    if (deactivateAll) {
      players.forEach(player => handlePlayerSelect(player));
      return;
    }

    players.forEach(player => handlePlayerSelect(player, true));
  };
  const handlePlayerSelect = (player: IPlayerPickerItemProps, forceActivate: boolean = false) => {
    let pendingPickedPlayers = [
      ...pickedPlayersSelector.homePending,
      ...pickedPlayersSelector.awayPending,
    ];
    let found =
      pendingPickedPlayers.find(pickedPlayer => pickedPlayer.id === player.player.id) !== undefined;
    if (found) {
      if (forceActivate) return;
      if (activeTeam === homeShortcut) {
        dispatch(removeHomePending(player.player));
      } else {
        dispatch(removeAwayPending(player.player));
      }
    } else {
      if (activeTeam === homeShortcut) {
        if (pickedPlayersSelector.homePending.length > 2) {
          toast.error('Lze vybrat maximálně 3 hráče z jednoho týmu.', {
            toastId: 'lotsOfPickedPlayers',
          });
          return;
        }
        dispatch(addHomePending(player.player));
      } else {
        if (pickedPlayersSelector.awayPending.length > 2) {
          toast.error('Lze vybrat maximálně 3 hráče z jednoho týmu.', {
            toastId: 'lotsOfPickedPlayers',
          });
          return;
        }
        dispatch(addAwayPending(player.player));
      }
    }
  };
  const handlePickedPlayers = () => {
    let pendingPickedPlayers = [
      ...pickedPlayersSelector.homePending,
      ...pickedPlayersSelector.awayPending,
    ];
    onPlayersPicked(pendingPickedPlayers);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
      <section className='ShotsModal PlayerPickerModal'>
        <div className='ShotsModal-header'>
          <div className='ShotsModal-header--team'>
            {homeShortcut && (
              <img src={require(`../../Assets/logos/${homeShortcut}.png`)} alt='team logo' />
            )}
            <p>{homeShortcut}</p>
          </div>
          <div className='ShotsModal-header--activeTeam'>
            <Filter
              paddingHorizontal={26}
              title={homeShortcut}
              active={activeTeam === homeShortcut}
              onClick={() => onActiveTeamChange(homeShortcut)}
            />
            <Filter
              paddingHorizontal={26}
              title={awayShortcut}
              active={activeTeam === awayShortcut}
              onClick={() => onActiveTeamChange(awayShortcut)}
            />
          </div>
          <div className='ShotsModal-header--team'>
            <p>{awayShortcut}</p>
            {awayShortcut && (
              <img src={require(`../../Assets/logos/${awayShortcut}.png`)} alt='team logo' />
            )}
          </div>
        </div>
        <div className='PlayerPickerModal-body'>
          <div className='PlayerPickerModal-body--part'>
            {sortedPlayers.forwards.map((players, index) => {
              return (
                <PlayerPickerRow
                  key={ATTACKER_ROWS[index] !== undefined ? ATTACKER_ROWS[index] : index}
                  title={ATTACKER_ROWS[index]}
                  data={players}
                  onRowClick={handleRowClick}
                  onItemClick={handlePlayerSelect}
                />
              );
            })}
          </div>
          <div className='PlayerPickerModal-body--part'>
            {sortedPlayers.backwards.map((players, index) => {
              return (
                <PlayerPickerRow
                  key={DEFENDERS_ROWS[index] + index}
                  title={DEFENDERS_ROWS[index]}
                  data={players}
                  onRowClick={handleRowClick}
                  onItemClick={handlePlayerSelect}
                />
              );
            })}
          </div>
        </div>
        <div className='ShotsModal-buttons'>
          <Buttons
            title='Zobrazit data'
            type='outlined'
            color='black'
            maxWidth={128}
            onClick={handlePickedPlayers}
          />
          <Buttons title='Zavřít' type='outlined' color='black' maxWidth={128} onClick={onClose} />
        </div>
      </section>
    </Dialog>
  );
};

const ATTACKER_ROWS = ['1. útok', '2. útok', '3. útok', '4. útok', undefined];
const DEFENDERS_ROWS = ['1. obrana', '2. obrana', '3. obrana'];

export default PlayerPickerModal;
