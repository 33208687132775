import { calculateCount, calculateCountMultiple, calculateSum, getRostedIds } from '.';
import {
  ICoordinates,
  IGameState,
  IGoal,
  IInterval,
  IMatch,
  IPlayer,
  IShot,
  IShotDanger,
  IShotsByTeam,
} from '../Types';

export const getTeamShots = (roster: IPlayer[], shots: IShot[]) => {
  const rosterIds = getRostedIds(roster);
  return [...shots.filter(shot => rosterIds.includes(shot.playerId))];
};

export const getTeamGoals = (roster: IPlayer[], goals: IGoal[]) => {
  const rosterIds = getRostedIds(roster);
  return [...goals.filter(goal => rosterIds.includes(goal.playerId))];
};

export const getPlayerShots = (playerId: string, shots: IShot[]) => {
  return [...shots.filter(shot => shot.playerId === playerId)];
};

export const getPlayerShotsCount = (playerId: string, shots: IShot[]) => {
  return getPlayerShots(playerId, shots).length;
};

export const getPlayerExpectedGoals = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateSum(playerShots, 'xG');
};

export const getPlayerSlotShotsCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCount(playerShots, 'inSlot', true);
};

export const getPlayerHighDangerShotsCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCount(playerShots, 'shotDanger', 'HD');
};

export const getPlayerLowDangerShotsCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCount(playerShots, 'shotDanger', 'LD');
};

export const getPlayerShotOnGoalCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCountMultiple(playerShots, 'result', ['saved', 'goal']);
};

export const getPlayerMissedShotsCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCount(playerShots, 'result', 'missed');
};

export const getPlayerBlockedShotsCount = (playerId: string, shots: IShot[]) => {
  const playerShots = getPlayerShots(playerId, shots);
  return calculateCount(playerShots, 'result', 'deflected');
};

export const getShotDetailModalType = (
  type: string,
): 'goal' | 'catched' | 'blocked' | undefined => {
  if (type === 'goal') return 'goal';
  if (type === 'saved') return 'catched';
  if (type === 'deflected') return 'blocked';
  return undefined;
};

export const filterShotsByType = (shots: IShot[], type: string): IShot[] =>
  [...shots].filter(shot => shot.result === type);

export const calculateShotPosition = (x: number, y: number, width: number = 760): ICoordinates => {
  const positionTop = x <= 0 ? Math.ceil(x + 100) : Math.ceil(100 - x);
  let positionLeft = Math.ceil((y + 100) / 2);
  positionLeft = x <= 0 ? positionLeft : 100 - positionLeft;
  const pos_x = Math.ceil((width * positionLeft) / 100);
  const pos_y = Math.ceil((width * positionTop) / 100);
  return {
    x: pos_x,
    y: pos_y,
  };
};

export const filterShots = (shots: IShot[]): IShot[] =>
  shots.filter(
    shot => Math.abs(shot.x) < 101 && Math.abs(shot.x) > 22 && shot.y < 101 && shot.y > -99,
  );

export const sortShotsByTeam = (match: IMatch, shots: IShot[]): IShotsByTeam => {
  return {
    home: shots.filter(
      shot => match.homeTeam.roster.find(player => player.id === shot.playerId) !== undefined,
    ),
    away: shots.filter(
      shot => match.awayTeam.roster.find(player => player.id === shot.playerId) !== undefined,
    ),
  };
};

export const isShotInIntervals = (intervals: IInterval[], shot: IShot): boolean =>
  intervals.find(interval => shot.time + 1 > interval.start && shot.time - 1 < interval.end) !==
  undefined;

export const getShotsByLineUp = (
  homeGameStates: IGameState[],
  awayGameStates: IGameState[],
  shots: IShotsByTeam,
  lineUp: string,
): IShotsByTeam => {
  if (lineUp === 'All') return shots;

  if (lineUp === '5:5') {
    let homeShots = filterShotsByLineUpAndGameState(['5:5'], homeGameStates, shots.home);
    let awayShots = filterShotsByLineUpAndGameState(['5:5'], awayGameStates, shots.away);
    return {
      home: homeShots,
      away: awayShots,
    };
  }

  return {
    home: filterPPShots(homeGameStates, shots.home),
    away: filterPPShots(awayGameStates, shots.away),
  };
};

export const filterShotsByLineUpAndGameState = (
  lineUps: string[],
  gameStates: IGameState[],
  shots: IShot[],
): IShot[] => {
  let timeArray: IInterval[] = [];
  [...gameStates].forEach(gameState => {
    if (lineUps.includes(gameState.state))
      timeArray.push({ start: gameState.start, end: gameState.end });
  });
  return [...shots].filter(shot => isShotInIntervals(timeArray, shot));
};

export const filterPPShots = (gameStates: IGameState[], shots: IShot[]): IShot[] => {
  let timeArray: IInterval[] = [];
  [...gameStates].forEach(gameState => {
    if (gameState.type === 'PP') timeArray.push({ start: gameState.start, end: gameState.end });
  });
  return [...shots].filter(shot => isShotInIntervals(timeArray, shot));
};

export const getShotDanger = (danger: IShotDanger) => {
  switch (danger) {
    case 'LD':
      return 'Nízká';
    case 'MD':
      return 'Střední';
    default:
      return 'Vysoká';
  }
};
