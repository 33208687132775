import { useEffect, useState } from 'react';

import HeadToHeadCharts from '../02-Macros/HeadToHeadCharts';
import FiltersContainer from '../03-Partials/FiltersContainer';
import FiltersRow from '../04-Micros/FiltersRow';
import Loading from '../04-Micros/Loading';
import { useGetHeadToHead } from '../../Hooks';
import { useAppSelector } from '../../Redux/store';
import { IIceTimeOptions } from '../../Types';
import { getGameStateFilters, getThirdsFilters, getThirdsInterval } from '../../Utils';

const HeadToHead = () => {
  const match = useAppSelector(state => state.match.value);

  const [gameStateActive, setGameStateActive] = useState<string>('');
  const [thirdsActive, setThirdsActive] = useState<string>('');

  const [options, setOptions] = useState<IIceTimeOptions>({
    start: undefined,
    end: undefined,
    gameState: undefined,
  });

  const { home, away, isLoading } = useGetHeadToHead(match, options);

  useEffect(() => {
    if (gameStateActive === 'All') {
      setOptions(prevState => ({ ...prevState, gameState: undefined }));
    } else {
      setOptions(prevState => ({ ...prevState, gameState: 'RG' }));
    }
    if (thirdsActive === 'All') {
      setOptions(prevState => ({
        ...prevState,
        start: undefined,
        end: undefined,
      }));
    } else {
      const { start, end } = getThirdsInterval(thirdsActive);
      setOptions(prevState => ({
        ...prevState,
        start,
        end,
      }));
    }
  }, [gameStateActive, thirdsActive]);

  return (
    <>
      <FiltersContainer>
        <FiltersRow
          filters={getGameStateFilters('PP', false)}
          active={gameStateActive}
          setActive={setGameStateActive}
        />
        <FiltersRow
          filters={getThirdsFilters(match.length, match.game)}
          active={thirdsActive}
          setActive={setThirdsActive}
        />
      </FiltersContainer>
      <main className='HeadToHead'>
        {isLoading ? (
          <Loading />
        ) : (
          <HeadToHeadCharts
            homeTeam={match.homeTeam.shortcut}
            awayTeam={match.awayTeam.shortcut}
            home={home}
            away={away}
          />
        )}
      </main>
    </>
  );
};

export default HeadToHead;
