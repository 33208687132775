import { useEffect, useState } from 'react';

import PlayerPickerModal from '../02-Macros/PlayerPickerModal';
import ShotsDetailModal from '../02-Macros/ShotsDetailModal';
import StatsSmallContainer from '../02-Macros/StatsSmallContainer';
import FiltersContainer from '../03-Partials/FiltersContainer';
import ShotsMap from '../03-Partials/ShotsMap';
import StatsBox from '../03-Partials/StatsBox';
import StatsDetailModal from '../03-Partials/StatsDetailModal';
import VideoModal from '../03-Partials/VideoModal';
import Buttons from '../04-Micros/Buttons';
import FiltersRow from '../04-Micros/FiltersRow';
import {
  addAwayPending,
  addHomePending,
  clearPendingPickedPlayers,
  clearPickedPlayers,
  setAway,
  setHome,
} from '../../Redux/pickedPlayers';
import { useAppDispatch, useAppSelector } from '../../Redux/store';
import { IPlayer, ITeamPowerPlayTime } from '../../Types';
import {
  filterShots,
  filterShotsBySelectedThird,
  getPlayerTeam,
  getPowerPlayByThirds,
  getShotsByLineUp,
  getShotsOfPickedPlayers,
  getShotsOfSelectedPlayer,
  getTeamsTimeInPowerPlay,
  getThirdsFilters,
  prepareShotsSmallStats,
  sortShotsByTeam,
} from '../../Utils';

const Shots = () => {
  const dispatch = useAppDispatch();
  const match = useAppSelector(state => state.match.value);
  const pickedPlayers = useAppSelector(state => state.pickedPlayers.value);
  const initialTeamShots = sortShotsByTeam(match, filterShots(match.shot));

  const [gameStateActive, setGameStateActive] = useState<string>('All');
  const [thirdsActive, setThirdsActive] = useState<string>('All');

  const [pickerModalState, setPickerModalState] = useState<boolean>(false);
  const [pickerModalTeam, setPickerModalTeam] = useState<string>('');
  const [detailModalState, setDetailModalState] = useState<boolean>(false);
  const [powerPlay, setPowerPlay] = useState<ITeamPowerPlayTime>({
    home: 0,
    away: 0,
  });
  const [teamShots, setTeamShots] = useState({
    home: initialTeamShots.home,
    away: initialTeamShots.away,
  });

  const [selectedPlayer, setSelectedPlayer] = useState<string>('');

  useEffect(() => {
    let shots = initialTeamShots;

    // Filtering shots by selected third
    shots = filterShotsBySelectedThird(thirdsActive, shots);
    shots = getShotsByLineUp(
      match.homeTeam.gameState,
      match.awayTeam.gameState,
      shots,
      gameStateActive,
    );

    if (pickedPlayers.home.length < 1 && pickedPlayers.away.length < 1) {
      setTeamShots(shots);
      return;
    }

    // Filtering shots by picked players
    shots = getShotsOfPickedPlayers(shots.home, shots.away, pickedPlayers.home, pickedPlayers.away);

    setTeamShots(shots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pickedPlayers,
    gameStateActive,
    thirdsActive,
    match.awayTeam.gameState,
    match.homeTeam.gameState,
  ]);

  useEffect(() => {
    let tmpPowerPlay = {
      home: getTeamsTimeInPowerPlay(match.homeTeam, match.length),
      away: getTeamsTimeInPowerPlay(match.awayTeam, match.length),
    };

    if (thirdsActive !== 'All') {
      tmpPowerPlay = getPowerPlayByThirds(
        match.homeTeam.gameState,
        match.awayTeam.gameState,
        thirdsActive,
      );
    }
    setPowerPlay(tmpPowerPlay);
  }, [match, thirdsActive]);

  const handlePickerModal = (team?: string) => {
    setPickerModalState(prevState => !prevState);
    if (team && typeof team === 'string') setPickerModalTeam(team);
  };
  const handlePickerModalClose = (team?: string) => {
    handlePickerModal(team);
    pickedPlayers.home.forEach(player => {
      if (!pickedPlayers.homePending.includes(player)) dispatch(addHomePending(player));
    });
    pickedPlayers.away.forEach(player => {
      if (!pickedPlayers.awayPending.includes(player)) dispatch(addAwayPending(player));
    });
  };
  const handlePlayersPicked = (players: IPlayer[]) => {
    setPickerModalState(prevState => !prevState);
    let home: IPlayer[] = [];
    let away: IPlayer[] = [];
    players.forEach(player => {
      let foundPlayer = [...match.homeTeam.roster].find(homePlayer => homePlayer.id === player.id);
      if (foundPlayer) home.push(player);
      else away.push(player);
    });
    dispatch(setHome(home));
    dispatch(setAway(away));
  };
  const handleDetailModal = (team?: string) => {
    setDetailModalState(prevState => !prevState);
    if (team && typeof team === 'string') {
      setPickerModalTeam(team);
    }
  };
  const handleTeamChange = (team: string) => {
    setPickerModalTeam(team);
  };
  const handleClearPickedPlayers = () => {
    dispatch(clearPickedPlayers());
    dispatch(clearPendingPickedPlayers());
    setSelectedPlayer('');
  };

  return (
    <>
      <FiltersContainer>
        <FiltersRow
          filters={[
            { label: 'Vše', value: 'All' },
            { label: '5v5', value: '5:5' },
            { label: 'PP', value: 'PP' },
          ]}
          active={gameStateActive}
          setActive={setGameStateActive}
        />
        <FiltersRow
          filters={getThirdsFilters(match.length, match.game)}
          active={thirdsActive}
          setActive={setThirdsActive}
        />
      </FiltersContainer>
      <main className='Shots'>
        <ShotsMap
          team={match.homeTeam.shortcut}
          filter='Všichni hráči'
          playerPicker={handlePickerModal}
          shotsDetail={handleDetailModal}
          data={teamShots.home}
          pickedPlayers={pickedPlayers.home}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
        <div className='Shots-center'>
          <p className='Shots-center--disclaimer'>Statistiky pouze pro útočné pásmo</p>
          <StatsSmallContainer>
            <>
              {gameStateActive === 'PP' && (
                <StatsBox
                  title='Čas odehraný v PP'
                  homeStats={powerPlay.home}
                  awayStats={powerPlay.away}
                  time
                />
              )}
              {prepareShotsSmallStats(
                getShotsOfSelectedPlayer(teamShots.home, selectedPlayer),
                getShotsOfSelectedPlayer(teamShots.away, selectedPlayer),
                match,
              ).map((stat, index) => (
                <StatsBox
                  key={index}
                  {...stat}
                  single={selectedPlayer !== '' ? getPlayerTeam(selectedPlayer, match) : undefined}
                />
              ))}
            </>
          </StatsSmallContainer>
          {(pickedPlayers.home.length > 0 || pickedPlayers.away.length > 0) && (
            <Buttons
              title='Smazat výběr hráčů'
              type='outlined'
              color='black'
              maxWidth={180}
              onClick={handleClearPickedPlayers}
            />
          )}
        </div>
        <ShotsMap
          team={match.awayTeam.shortcut}
          filter='Všichni hráči'
          playerPicker={handlePickerModal}
          shotsDetail={handleDetailModal}
          data={teamShots.away}
          pickedPlayers={pickedPlayers.away}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
      </main>
      <StatsDetailModal />
      <PlayerPickerModal
        open={pickerModalState}
        activeTeam={pickerModalTeam}
        homeShortcut={match.homeTeam.shortcut}
        awayShortcut={match.awayTeam.shortcut}
        data={
          pickerModalTeam === match.homeTeam.shortcut
            ? match.homeTeam.roster
            : match.awayTeam.roster
        }
        pickedPlayers={[...pickedPlayers.home, ...pickedPlayers.away]}
        onActiveTeamChange={handleTeamChange}
        onClose={handlePickerModalClose}
        onPlayersPicked={handlePlayersPicked}
      />
      <ShotsDetailModal
        open={detailModalState}
        onClose={handleDetailModal}
        onTeamChange={handleTeamChange}
        activeTeam={pickerModalTeam}
        homeShortcut={match.homeTeam.shortcut}
        awayShortcut={match.awayTeam.shortcut}
        data={
          pickerModalTeam === match.homeTeam.shortcut
            ? getShotsOfSelectedPlayer(teamShots.home, selectedPlayer)
            : getShotsOfSelectedPlayer(teamShots.away, selectedPlayer)
        }
        players={
          pickerModalTeam === match.homeTeam.shortcut
            ? match.homeTeam.roster
            : match.awayTeam.roster
        }
      />
      <VideoModal />
    </>
  );
};

export default Shots;
