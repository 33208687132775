import { FC } from 'react';

import FormationsMap from '../03-Partials/FormationsMap';
import StatsBoxSimple from '../03-Partials/StatsBoxSimple';
import FormationsStatsPlayer from '../04-Micros/FormationsStatsPlayer';
import { IExtendedFormation, IShotsByTeam } from '../../Types';
import { filterShotsByType, formatSeconds, getPlayerShots } from '../../Utils';

interface IProps extends IExtendedFormation {
  shots: IShotsByTeam;
  teamActive: string;
}

const FormationsRow: FC<IProps> = ({ players, metrics, shots, teamActive }) => {
  return (
    <div className='FormationsRow'>
      <FormationsMap title='Ofenzíva' shots={teamActive === 'home' ? shots.home : shots.away} />
      <div className='FormationsStats'>
        <div className='FormationsStats-playersWrapper'>
          {players.map(player => {
            const playerShots = getPlayerShots(player.id, [...shots.home, ...shots.away]);
            const playerGoals = filterShotsByType(playerShots, 'goal');
            return (
              <FormationsStatsPlayer
                key={player.id}
                jersey={player.jersey}
                name={player.name}
                surname={player.surname}
                shots={playerShots.length}
                goals={playerGoals.length}
              />
            );
          })}
        </div>
        <StatsBoxSimple title='Střely' homeStats={metrics.CF} awayStats={metrics.CA} />
        <StatsBoxSimple title='Góly' homeStats={metrics.GF} awayStats={metrics.GA} />
        <StatsBoxSimple title='Střely ze slotu' homeStats={metrics.sCF} awayStats={metrics.sCA} />
        <StatsBoxSimple title='Čas na ledě' time={formatSeconds(metrics.TOI)} />
      </div>
      <FormationsMap title='Defenzíva' shots={teamActive === 'home' ? shots.away : shots.home} />
    </div>
  );
};

export default FormationsRow;
