//React
import React from 'react';
import ReactDOM from 'react-dom/client';
//Redux
import { Provider } from 'react-redux';

import { APP_ENVIROMENT } from './Api';
//Components
import App from './App';
import store from './Redux/store';
//Styles
import './SCSS/main.scss';

//Material UI
//Others

const element =
  APP_ENVIROMENT === 'prod' ? (
    <Provider store={store}>
      <App />
    </Provider>
  ) : (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(element);
