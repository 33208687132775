import { FC } from 'react';

import Shot from '../04-Micros/Shot';
import { IShot } from '../../Types';
import { calculateShotPosition } from '../../Utils';

interface IProps {
  title: string;
  shots?: IShot[];
}

const FormationsMap: FC<IProps> = ({ title, shots }) => {
  return (
    <div className='ShotsMap'>
      <h3 className='ShotsMap-title'>{title}</h3>
      <div className='ShotsMap-container'>
        <img src={require('../../Assets/hriste.png')} alt='' />
        {shots?.map((shot, index) => {
          const position = calculateShotPosition(shot.x, shot.y, 360);
          return <Shot key={index} {...position} isGoal={shot.result === 'goal'} />;
        })}
      </div>
    </div>
  );
};

export default FormationsMap;
