import MomentumChart from '../02-Macros/MomentumChart';
import VideoModal from '../03-Partials/VideoModal';
import { useAppSelector } from '../../Redux/store';

const Momentum = () => {
  const match = useAppSelector(state => state.match.value);

  return (
    <>
      <main className='Momentum'>
        <MomentumChart
          homeTeam={match.homeTeam.shortcut}
          awayTeam={match.awayTeam.shortcut}
          momentum={match.momentum}
        />
      </main>
      <VideoModal />
    </>
  );
};

export default Momentum;
