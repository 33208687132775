import { FC } from 'react';

interface IProps {
  valueLeft: string;
  valueRight: string | number;
  active?: boolean;
  main?: boolean;
  vertical?: boolean;
  highlight?: number;
  disabled?: boolean;
  onClick?: () => void;
}

const SelectTab: FC<IProps> = ({
  valueLeft,
  valueRight,
  active,
  main,
  vertical,
  highlight,
  disabled,
  onClick,
}) => {
  return (
    <div className={`Wrapper${active ? ' active' : ''}${vertical ? ' vertical' : ''}`}>
      <div
        className={`SelectTab
				${main ? ' main' : ''}
				${active ? ' active' : ''}
				${vertical ? ' vertical' : ''}
				${disabled ? ' disabled' : ''}`}
        onClick={onClick}
      >
        <p>{valueLeft}</p>
        <p>{valueRight}</p>
      </div>
      {highlight && (
        <div
          className='highlight'
          style={{ width: !vertical ? highlight : 32, height: vertical ? highlight : 32 }}
        ></div>
      )}
    </div>
  );
};

export default SelectTab;
