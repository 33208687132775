import { IHeadToHeadState } from '../Types';
import { axiosInstance } from './axiosInstance';

export const getHomeToAway = async (
  token: string,
  matchId: string,
  timeFrom?: number,
  timeTo?: number,
  gameState?: string,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const filterTime = timeFrom !== undefined ? `?timeFrom=${timeFrom}&timeTo=${timeTo}` : '';
  const filterGameState = gameState ? `${filterTime ? '&' : '?'}gameState=${gameState}` : '';

  const response: IHeadToHeadState = await axiosInstance.get(
    `/home-to-away/${matchId}${filterTime}${filterGameState}`,
    {
      headers: headers,
    },
  );
  return response;
};

export const getAwayToHome = async (
  token: string,
  matchId: string,
  timeFrom?: number,
  timeTo?: number,
  gameState?: string,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const filterTime = timeFrom !== undefined ? `?timeFrom=${timeFrom}&timeTo=${timeTo}` : '';
  const filterGameState = gameState ? `${filterTime ? '&' : '?'}gameState=${gameState}` : '';

  const response: IHeadToHeadState = await axiosInstance.get(
    `/away-to-home/${matchId}${filterTime}${filterGameState}`,
    {
      headers: headers,
    },
  );
  return response;
};
