import { FC } from 'react';

import { useVideoDVR } from '../../Hooks/useVideo';
import { IVideo } from '../../Types';
import VideoFrame from './VideoFrame';

interface IProps {
  video: IVideo;
}

const VideoFrameWrapperDVR: FC<IProps> = ({ video }) => {
  const { html, key, errorEvent } = useVideoDVR(video);

  if (video.videoTime === null) {
    return <div className='VideoFrameWrapperDVR__error'>Video bude brzy dostupné.</div>;
  }

  if (errorEvent) {
    return (
      <div className='VideoFrameWrapperDVR__error'>
        Video této události aktuálně není dostupné. K dispozici bude po skončení zápasu.
      </div>
    );
  }

  return <VideoFrame html={html} key={key} />;
};

export default VideoFrameWrapperDVR;
