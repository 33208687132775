import { IFormationsState } from '../Types';
import { axiosInstance } from './axiosInstance';

export const getFormations = async (token: string, matchId: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response: IFormationsState = await axiosInstance.get(`/formation/${matchId}`, {
    headers: headers,
  });
  return response;
};
