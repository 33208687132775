import { IListState, IMatchDetailState } from '../Types';
import { axiosInstance } from './axiosInstance';

export const getMatches = async (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response: IListState = await axiosInstance.get('/schedule-list', {
    headers: headers,
  });
  return response;
};

export const getMatchDetails = async (token: string, matchId: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response: IMatchDetailState = await axiosInstance.get(`/entry/${matchId}`, {
    headers: headers,
  });

  return response;
};
