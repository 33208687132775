import { IInterval, IPlayerPosition, ISessionLoginObject } from '../Types';
import { isInTime } from './matches';

export const setToSessionWithExpiration = (value: string, seconds: number, name: string) => {
  const expiredAt = new Date(new Date().getTime() + seconds * 1000);
  const obj: ISessionLoginObject = {
    value: value,
    expiredAt: expiredAt.toISOString(),
  };
  sessionStorage.setItem(name, JSON.stringify(obj));
};

export const getSessionWithExpiration = (name: string) => {
  const value = sessionStorage.getItem(name);
  if (value !== null) {
    const obj: ISessionLoginObject = JSON.parse(value);
    const expiredAt = new Date(obj.expiredAt);
    if (expiredAt > new Date()) {
      return obj.value;
    } else {
      sessionStorage.removeItem(name);
    }
  }
  return null;
};

export const setToSession = (name: string, value: string) => sessionStorage.setItem(name, value);

export const getSession = (name: string) => sessionStorage.getItem(name);

export const calculateSum = (array: any[], att: string) => {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[att];
  }, 0);

  return Number(total.toFixed(2));
};

export const calculateCount = (array: any[], att: string, value: any) => {
  const total = array.reduce((accumulator, object) => {
    if (object[att] === value) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);

  return total;
};

export const calculateCountMultiple = (array: any[], att: string, value: any[]) => {
  const total = array.reduce((accumulator, object) => {
    if (value.includes(object[att])) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);

  return total;
};

export const calculateCountInterval = (
  array: any[],
  att: string,
  start: number,
  end: number,
  increment: number = 0,
) => {
  const total = array.reduce((accumulator, object) => {
    if (isInTime(start, end, object[att] + increment)) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);

  return total;
};

export const getPlayerPosition = (position: IPlayerPosition) => {
  switch (position) {
    case 'forward':
      return 'Útočník';
    case 'backward':
      return 'Obránce';
    default:
      return 'Brankář';
  }
};

export const getPercent = (value: number, outOf: number, fixed: number = 1) => {
  if (outOf === 0) {
    return '100.0%';
  } else {
    return `${((value / outOf) * 100).toFixed(fixed)}%`;
  }
};

export const removeDuplicates = (arr: any[], key: string) => {
  let map = new Map();
  arr.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
};

export const calculateXGDecimal = (xG: number) => Math.round(xG * 100) / 100;

export const deepCopy = <T>(arg: T): T => JSON.parse(JSON.stringify(arg));

export const getIntervalIntersection = (a: IInterval, b: IInterval) => {
  const min = a.start < b.start ? a : b;
  const max = min === a ? b : a;

  if (min.end < max.start) {
    return 0;
  }
  return (min.end < max.end ? min.end : max.end) - max.start;
};

export const GREEN_HIGHLIGHT = ['#c3fae8', '#96f2d7', '#38d9a9', '#12b886', '#099268'];

export const RED_HIGHLIGHT = ['#ffe3e3', '#ffc9c9', '#ff8787', '#fa5252', '#e03131'];

export const PAGE_TIPS = {
  matches: '',
  overview: `
        Obrazovka <b>Přehled</b> nabízí základní údaje o průběhu zápasu - střely, očekávané góly, nebo bilanci vhazování.</br>
        </br>
        Pro rychlejší orientaci je u každé statistiky barevný box, který ukazuje převahu v dané kategorii.</br>
        </br>
        Při kliknutí na vybranou metriku se zobrazí tabulka s individuálními výkony hráčů obou týmů. Pomocí tlačítka ""Play"" vedle jmen hráčů máte možnost si přehrát události vybraného hráče spadající pod vybranou metriku.</br>
        </br>
        Aplikace zároveň nabízí na všech obrazovkách filtraci dle herních situací a jednotlivých hracích částí.</br>
        </br>
        Pokud narazíte v aplikaci na nějakou chybu, máte možnost ji nahlásit z hlavního panelu vpravo nahoře na jakékoliv obrazovce, nebo při přehrávání videa.
    `,
  shots: `
        Obrazovka <b>Střely</b> nabízí možnost hlubší analýzy střel obou týmů.</br>
        </br>
        K základním údajům přidává detailní mapu střel s jejich lokacemi a nebezpečností jednotlivých střel. Na detailu mapy střel máte možnost přehrát si video všech střel.</br>
        </br>
        Je zde také možnost <b>"Výběr hráčů"</b> (max. 3 z každého týmu), což následně vyfiltruje všechny zobrazované metriky na této obrazovce pouze na čas, který spolu odehráli vybraní hráči.
        Filtrace probíhá kliknutím na tlačítko "Výběr hráčů" a následně na jména požadovaných hráčů. Po zvolení hráčů zobrazíte vyfiltrovaná data kliknutím na tlačítko "Zobrazit data". U vyfiltrovaných hráčů se Vám zobrazí počet jejich střel (S) a gólů (G) v zápase. Výběr můžete následně opět odstranit kliknutím na tlačítko "Smazat výběr hráčů".</br>
        </br>
        Při vyfiltrování hráčů máte možnost vybrat si konkrétního hráče kliknutím na jeho jméno pod mapou střel. To vyfiltruje individuální statistiky vybraného hráče.
    `,
  formations: `
        Obrazovka <b>Srovnání formací</b> nabízí porovnání 4 nejstabilnějších útočných trojic (dle společně odehraného času v zápase) a 3 nejstabilnějších obranných dvojic.</br>
        </br>
        Volit mezi útočným a obrannými formacemi můžete pomocí filtru v horní části (5v5 útoky a 5v5 obrany).</br>
        </br>
        Sledovat můžete také oba týmy dle filtrů ve stejné části obrazovky. Pro každou trojici či dvojici je zobrazen společně odehraný čas, střely pro a proti, střely ze slotu pro a proti a vstřelené a inkasované góly. U každého hráče je zobrazen počet jeho střel (S) a gólů (G) v zápase.
    `,
  goalkeepers: `
        Obrazovka <b>Brankáři</b> nabízí porovonání výkonů brankářů obou týmu.</br>
        </br>
        Statistika <b>Góly chycené nad očekávání</b> kvantifikuje brankářovy zásluhy a vyčísluje, o kolik gólů díky brankářovi tým dostal méně (pro kladné hodnoty), nebo více (pro záporné hodnoty) po zohlednění kvality střel.</br>
        </br>
        Dále obrazovka nabízí počet zákroků brankářů, jejich % úspěšnost a počet obdržených gólů. Na grafice jsou znázorněné zóny branky s počtem inkasovaných gólů a celkovým počtem střel do dané zóny. Zóny branky jsou vykresleny z pohledu střelce – tedy zóna vlevo nahoře znázorňuje střely, které šly nad brankářovo pravé rameno atd.
    `,
  faceOff: `
        Obrazovka <b>Vhazování</b> nabízí detailní přehled o výkonu týmů a jednotlivých hráčů na vhazování v průběhu zápasu.</br>
        </br>
        K dispozici je detailní filtrace dle jednotlivých bodů pro vhazování, nebo jednotlivých duelů hráčů z obou týmů. A to kliknutím a vybraný kruh pro vhazování, nebo jména vybraných hráčů.</br>
        </br>
        Kliknutím na dlaždici bilance vhazování máte možnost přehrát si události vhazování jednotlivých hráčů na videu.
    `,
  iceTime: `
        Obrazovka <b>IceTime</b>  nabízí přehled odehraného času hráčů vybraného týmu a graf jednotlivých střídání hráčů.<br />
        <br />
        Ve vrchní části obrazovky je k dispozici filtrace pro jednotlivé týmy a třetiny. Primární zobrazení je vždy pro aktuálně probíhající třetinu.<br />
        <br />
        Obrazovka nabízí celkový odehraný čas hráčů vybraného týmu (TOT), odehraný čas v přesilové hře (PP) a odehraný čas v oslabení (SH) a v podobě jednotlivých obdelníků také grafické znázornění střídání všech hráčů jejichž barva se mění v závislosti na počtu hráčů na ledě z pohledu vybraného týmu. Černá barva značí stav 5:5, zelená značí přesilovou hru, červená oslabení a šedá hru 4:4.<br />
        <br /> 
        Při zapnutí podrobností (vpravo nahoře) lze získat také informace o tom, ve kterém střídání padl gól a kteří hráči byli na ledě, a také po kliknutí na vybraný obdelník střídání lze získat přesné údaje od kdy do kdy byl hráč ve vybraném střídání na ledě.
    `,
  h2h: `
        Obrazovka <b>Kdo proti komu</b> nabízí přehled odehraného času všech hráčů obou týmů.</br>
        </br>
        Grafická matice ukazuje, proti komu nejčastěji jednotliví hráči nastupovali a jak si jejich tým vedl z pohledu tvorby šancí. Velikost kruhu značí čas, který který hráči proti sobě strávili na ledě, jeho barevné rozdělení symbolizuje, který tým měl navrch z pohledu metriky očekávaných gólů.
    `,
  momentum: `
        Obrazovka <b>Vývoj zápasu</b>  ukazuje, který tým si v průběhu zápasu vytvořil střeleckou převahu.</br>
        </br>
        Po kliknutí na ikonu gólu máte možnost přehrát si video vstřeleného gólu.
    `,
  predictions: '',
};

export const PAGE_TITLE = {
  matches: 'Zápasy',
  overview: 'Přehled',
  shots: 'Střely',
  formations: 'Srovnání formací',
  goalkeepers: 'Brankáři',
  faceOff: 'Vhazování',
  iceTime: 'IceTime',
  predictions: 'Predikce',
  h2h: 'Kdo proti komu',
  momentum: 'Vývoj zápasu',
};

export const DEFAULT_VIDEO_CUT_BEFORE = 5;
export const DEFAULT_VIDEO_SEGMENT = 10;
