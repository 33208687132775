import { axiosInstance } from './axiosInstance';

interface IQueryParams {
  token: string;
  issueType: string;
  description: string;
  match: string;
  eventType?: string;
  eventTime?: string;
}

export const postBugReport = async ({
  token,
  issueType,
  description,
  match,
  eventType,
  eventTime,
}: IQueryParams) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const body = {
    issueType,
    description,
    match,
    eventType,
    eventTime,
  };

  const response = await axiosInstance.post(`/issue`, body, {
    headers,
  });
  return response.data;
};
