import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import StatsModal from '../02-Macros/StatsModal';
import MatchInfo, { IMatchInfo } from '../03-Partials/MatchInfo';
import Loading from '../04-Micros/Loading';
import { getMatches } from '../../Api';
import { ChartBarIcon, LogoutIcon } from '../../Icons';
import { setLogout } from '../../Redux/login';
import { useAppDispatch, useAppSelector } from '../../Redux/store';
import { formatMatchInfo } from '../../Utils';

const Matches = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(state => state.login.token.access_token);
  const user = useAppSelector(state => state.login.identity);

  const [statsModal, setStatsModal] = useState<boolean>(false);
  const [matches, setMatches] = useState<IMatchInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getMatches(token)
        .then(res => {
          setMatches([]);
          res.data.forEach(match => {
            setMatches(prevState => [...prevState, formatMatchInfo(match)]);
          });
          setIsLoading(false);
        })
        .catch(err => console.log('Get matches err:', err));
    }
  }, [token]);

  const handleLogOut = () => {
    dispatch(setLogout());
    sessionStorage.clear();
    navigate('/login');
    toast.success('Odhlášení proběhlo úspěšně.', {
      toastId: 'logoutSucces',
    });
  };

  const handleStatsModal = () => setStatsModal(prevState => !prevState);

  return (
    <main className='Matches'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='Matches-header'>
            {user.role === 'admin' && (
              <Button
                variant='text'
                startIcon={<ChartBarIcon />}
                onClick={handleStatsModal}
                style={{ marginLeft: 'auto' }}
              >
                Zobrazit statistiky
              </Button>
            )}
            <Button variant='text' startIcon={<LogoutIcon />} onClick={handleLogOut}>
              Odhlásit se
            </Button>
          </div>
          <h1 className='Matches-title'>Tipsport extraliga</h1>
          {matches.length > 0 ? (
            <div className='Matches-matchesList'>
              {matches.map(match => (
                <MatchInfo key={match.matchId} {...match} />
              ))}
            </div>
          ) : (
            <div className='Matches-noData'>
              <h1>Váš tým dnes nemá na programu žádný zápas.</h1>
            </div>
          )}
          {statsModal && <StatsModal open={statsModal} onClose={handleStatsModal} />}
        </>
      )}
    </main>
  );
};

export default Matches;
