import { FC } from 'react';

import { IVideo } from '../../Types';
import { calculateXGDecimal, formatSeconds, getShotDanger } from '../../Utils';

const VideoHeader: FC<IVideo> = ({ player, time, type, gameState, danger, xG }) => {
  let INFO = [
    {
      title: 'Čas:',
      value: formatSeconds(time),
    },
    {
      title: 'Kategorie:',
      value: type,
    },
    {
      title: 'Hráčů na ledě:',
      value: gameState,
    },
  ];

  if (danger && xG) {
    INFO = [
      ...INFO,
      { title: 'Nebezpečnost', value: getShotDanger(danger) },
      { title: 'xG:', value: `${calculateXGDecimal(xG).toFixed(2)}` },
    ];
  }

  return (
    <header className='VideoHeader'>
      <p className='VideoHeader-player'>
        {player.jersey} <span>{player.name}</span> {player.surname}
      </p>
      <div className='VideoHeader-infoContainer'>
        {INFO.map((item, index) => (
          <div className='VideoHeader-infoItem' key={index}>
            <p className='VideoHeader-infoItem--title'>{item.title}</p>
            <p className='VideoHeader-infoItem--value'>{item.value}</p>
          </div>
        ))}
      </div>
    </header>
  );
};

export default VideoHeader;
