import { FC } from 'react';

type TProps = {
  children?: JSX.Element[] | JSX.Element;
};

const FiltersContainer: FC<TProps> = ({ children }) => {
  return <div className='FiltersContainer'>{children}</div>;
};

export default FiltersContainer;
