import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getIdentity } from '../../Api';
import { setIdentity, setSessionToken } from '../../Redux/login';
import { navigateTo } from '../../Redux/navigation';
import { useAppDispatch, useAppSelector } from '../../Redux/store';
import { TNavigateUrl } from '../../Types/navigation';
import { getSessionWithExpiration } from '../../Utils';

interface IProps {
  children: JSX.Element;
}

const ProtectedRoute: FC<IProps> = ({ children }) => {
  const isLogged = useAppSelector(state => state.login.logged);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLogged) {
      handleSession();
    }
    dispatch(navigateTo(location.pathname.replace('/', '') as TNavigateUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSession = () => {
    const token = getSessionWithExpiration('token');
    if (token) {
      dispatch(setSessionToken(token));
      getIdentity(token)
        .then(res => {
          dispatch(setIdentity(res.data));
        })
        .catch(err => console.log('Get identity err:', err));
    } else {
      navigate('/login');
      toast.error('Platnost přihlášení vypršela.', {
        toastId: 'loginExpired',
      });
    }
  };

  return children;
};

export default ProtectedRoute;
