import { IPlayer, IShift, IShot } from '../Types';

export const transformPlayerShiftToArray = (shifts: IShift[]): number[][] => {
  if (!shifts) return [];
  return [...shifts].map(shift => [shift[0].from, shift[0].to]);
};

export const findCommonShifts = (
  originalShifts: number[][],
  playerShifts: number[][],
): number[][] => {
  let originalShiftArray = [...originalShifts];
  let playerShiftArray = [...playerShifts];
  let resultArray: number[][] = [];
  originalShiftArray.forEach(originalShift => {
    playerShiftArray.forEach(playerShift => {
      if (originalShift[0] < playerShift[0] + 1 && originalShift[1] > playerShift[0]) {
        if (originalShift[1] > playerShift[1]) {
          resultArray.push([playerShift[0], playerShift[1]]);
        } else {
          resultArray.push([playerShift[0], originalShift[1]]);
        }
      } else if (originalShift[0] + 1 > playerShift[0] && originalShift[0] < playerShift[1]) {
        if (originalShift[1] > playerShift[1]) {
          resultArray.push([originalShift[0], playerShift[1]]);
        } else {
          resultArray.push([originalShift[0], originalShift[1]]);
        }
      } else if (originalShift[0] + 1 > playerShift[0] && originalShift[1] - 1 < playerShift[1]) {
        resultArray.push([originalShift[0], originalShift[1]]);
      }
    });
  });
  return resultArray;
};

export const filterShotsInIntervals = (shots: IShot[], timeArray: number[][]): IShot[] =>
  [...shots].filter(shot =>
    [...timeArray].find(interval => interval[0] < shot.time + 1 && interval[1] > shot.time - 1),
  );

export const getCommonShiftArray = (players: IPlayer[]): number[][] => {
  let playerArray = [...players];
  let originalShiftArray: number[][] = transformPlayerShiftToArray(playerArray[0].timeOnIce.shifts);
  playerArray.forEach((player, index) => {
    if (index === 0) return;

    let playerShiftArray = transformPlayerShiftToArray(player.timeOnIce.shifts);
    originalShiftArray = findCommonShifts(originalShiftArray, playerShiftArray);
  });
  return originalShiftArray;
};

export const getShotsOfPickedPlayers = (
  shotsHome: IShot[],
  shotsAway: IShot[],
  playersHome: IPlayer[],
  playersAway: IPlayer[],
) => {
  let array = [...shotsHome];
  let originalShiftArray: number[][] = getCommonShiftArray([...playersHome, ...playersAway]);
  let homeShots = filterShotsInIntervals(array, originalShiftArray);
  let awayShots = filterShotsInIntervals([...shotsAway], originalShiftArray);
  return {
    home: homeShots,
    away: awayShots,
  };
};

export const getShotsOfSelectedPlayer = (shots: IShot[], playerId: string) => {
  return playerId !== '' ? shots.filter(shot => shot.playerId === playerId) : shots;
};

export const filterShotsByPlayerId = (shots: IShot[], playerID: string): IShot[] =>
  shots.filter(shot => shot.playerId === playerID);

export const getFormationPlayers = (playerIds: string[], players: IPlayer[]): IPlayer[] => {
  const playerArray = playerIds.reduce<IPlayer[]>((acc, playerId) => {
    const foundPlayer = players.find(teamPlayer => teamPlayer.id === playerId);
    if (foundPlayer) acc.push(foundPlayer);
    return acc;
  }, []);

  return playerArray;
};
