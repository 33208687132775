import { FC } from 'react';

export interface IStatsBoxSimple {
  title: string;
  homeStats?: string | number;
  awayStats?: string | number;
  time?: string;
  disabled?: boolean;
}

const StatsBoxSimple: FC<IStatsBoxSimple> = ({ homeStats, awayStats, title, disabled, time }) => {
  return (
    <div className={`StatsBoxSimple${disabled ? ' disabled' : ''}`}>
      <p className='StatsBoxSimple-title'>{title}</p>
      {homeStats !== undefined && (
        <h1 className='StatsBoxSimple-stats'>{`${homeStats} - ${awayStats}`}</h1>
      )}
      {time && <h1 className='StatsBoxSimple-stats'>{time}</h1>}
    </div>
  );
};

export default StatsBoxSimple;
