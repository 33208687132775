import { FC } from 'react';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const StatsBigContainer: FC<IProps> = ({ children }) => {
  return <div className='StatsBigContainer'>{children}</div>;
};

export default StatsBigContainer;
