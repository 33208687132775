//Redux
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IVideo, IVideoDvrRecord } from '../Types';

//Types

interface IModal {
  value: {
    state: boolean;
    active: number;
    videos: IVideo[];
    dvrChannels?: IVideoDvrRecord;
    dvrId?: string;
  };
}

const videoInitialState: IModal = {
  value: {
    state: false,
    active: 0,
    videos: [],
    dvrChannels: undefined,
    dvrId: undefined,
  },
};

export const videoSlice = createSlice({
  name: 'video',
  initialState: videoInitialState,
  reducers: {
    toggleVideo: state => {
      if (state.value.state) {
        state.value.active = 0;
      }
      state.value.state = !state.value.state;
    },
    fillVideoData: (state, action: PayloadAction<IVideo[]>) => {
      state.value.videos = action.payload;
    },
    setActiveVideo: (state, action: PayloadAction<number>) => {
      state.value.active = action.payload;
    },
    setDvrChannels: (state, action: PayloadAction<IVideoDvrRecord | undefined>) => {
      state.value.dvrChannels = action.payload;
    },
    setDvrId: (state, action: PayloadAction<string | undefined>) => {
      state.value.dvrId = action.payload;
    },
  },
});

export const { toggleVideo, fillVideoData, setActiveVideo, setDvrChannels, setDvrId } =
  videoSlice.actions;
export default videoSlice.reducer;
