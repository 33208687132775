import { FC, useState } from 'react';

import { useClickOutside } from '../../Hooks';
import { IGoal, IIceTimeOptions, IIntervalWithColor } from '../../Types';
import {
  formatSeconds,
  getLeftValueOnTimeline,
  getPositionOnTimeline,
  getTeamDetailMark,
  isInTime,
} from '../../Utils';

interface IProps {
  playerId: string;
  shift: IIntervalWithColor;
  teamGoals: IGoal[];
  opponentGoals: IGoal[];
  details?: boolean;
  options: IIceTimeOptions;
  timelineWidth: number;
}

const IceTimeShift: FC<IProps> = ({
  playerId,
  shift,
  teamGoals,
  opponentGoals,
  details,
  options,
  timelineWidth,
}) => {
  const [tooltip, setTooltip] = useState<boolean>(false);

  const handleTooltip = () => setTooltip(prevState => !prevState);

  const ref = useClickOutside<HTMLDivElement>(() => {
    setTooltip(false);
  }, []);

  const filteredTeamGoals =
    options.start !== undefined
      ? teamGoals.filter(shot => isInTime(options.start!, options.end!, shot.time))
      : teamGoals;

  const filteredOpponentGoals =
    options.start !== undefined
      ? opponentGoals.filter(shot => isInTime(options.start!, options.end!, shot.time))
      : opponentGoals;

  return (
    <>
      {details &&
        filteredTeamGoals.map((shot, index) => {
          if (isInTime(shift.start, shift.end, shot.time - 2)) {
            return (
              <div
                key={index}
                className='IceTimeShift-shot'
                onClick={details ? handleTooltip : undefined}
                style={{
                  ...getLeftValueOnTimeline(shot.time - 2, timelineWidth),
                  cursor: details ? 'pointer' : 'auto',
                }}
              >
                {getTeamDetailMark(shot, playerId)}
              </div>
            );
          }
          return null;
        })}
      {details &&
        filteredOpponentGoals.map((shot, index) => {
          if (isInTime(shift.start, shift.end, shot.time - 2)) {
            return (
              <div
                key={index}
                className='IceTimeShift-shot'
                onClick={details ? handleTooltip : undefined}
                style={{
                  ...getLeftValueOnTimeline(shot.time - 2, timelineWidth),
                  cursor: details ? 'pointer' : 'auto',
                }}
              >
                -
              </div>
            );
          }
          return null;
        })}
      <div
        ref={ref}
        onClick={details ? handleTooltip : undefined}
        className={`IceTimeShift${tooltip ? ' showTooltip' : ''}`}
        style={{
          ...getPositionOnTimeline(
            {
              start: shift.start,
              end: shift.end,
            },
            timelineWidth,
          ),
          backgroundColor: shift.color,
          cursor: details ? 'pointer' : 'auto',
        }}
      >
        <div className='IceTimeShift-tooltipContainer'>
          <div className='IceTimeShift-tooltip'>
            <p>
              {formatSeconds(shift.start)} - {formatSeconds(shift.end)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IceTimeShift;
