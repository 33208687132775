import { Dialog } from '@mui/material';
import { FC } from 'react';

import Buttons from '../04-Micros/Buttons';
import { IChangelog } from '../../Types';
import { formatDate } from '../../Utils';

interface IProps {
  open: boolean;
  onClose: () => void;
  changelog: IChangelog;
}

const ChangelogModal: FC<IProps> = ({ open, onClose, changelog }) => {
  return (
    <Dialog className='ChangelogModal' open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <div className='Changelog'>
        <div className='Changelog-text'>
          {Object.values(changelog).map((item, index) => (
            <div key={index}>
              <div className='Changelog-versionHeader'>
                <h3>Verze {item.id}</h3>
                <p>{formatDate(new Date(item.date), 'P')}</p>
              </div>
              <ul>
                {item.changes.map((change, index) => (
                  <li key={index}>{change}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <Buttons
          title='Zavřít'
          color='loginBlue'
          type='contained'
          padding={14}
          fontSize={14}
          onClick={onClose}
        />
      </div>
    </Dialog>
  );
};

export default ChangelogModal;
