import { FC } from 'react';

import { ArrowsClockwiseIcon } from '../../Icons';
import { useAppSelector } from '../../Redux/store';
import { GREEN_HIGHLIGHT, RED_HIGHLIGHT } from '../../Utils';

interface IProps {
  homeTeam: string;
  awayTeam: string;
  homePosition: 'top' | 'left';
  awayPosition: 'top' | 'left';
  setHomePosition: (pos: 'top' | 'left') => void;
  setAwayPosition: (pos: 'top' | 'left') => void;
}

const HeadToHeadTeamPanel: FC<IProps> = ({
  homeTeam,
  awayTeam,
  homePosition,
  awayPosition,
  setHomePosition,
  setAwayPosition,
}) => {
  const identity = useAppSelector(state => state.login.identity);
  const awayId = useAppSelector(state => state.match.value.awayTeam.id);

  const switcheTeamsPosition = () => {
    const homeTmp = homePosition;
    setHomePosition(awayPosition);
    setAwayPosition(homeTmp);
  };

  return (
    <div className='HeadToHeadTeamPanel'>
      <div className={`HeadToHeadTeamPanel-team ${homePosition}`}>
        <p>{homeTeam}</p>
        <div
          className='dot'
          style={{
            backgroundColor: (identity.teamId === awayId ? RED_HIGHLIGHT : GREEN_HIGHLIGHT)[4],
          }}
        ></div>
      </div>
      <div className={`HeadToHeadTeamPanel-team ${awayPosition}`}>
        <p>{awayTeam}</p>
        <div
          className='dot'
          style={{
            backgroundColor: (identity.teamId === awayId ? GREEN_HIGHLIGHT : RED_HIGHLIGHT)[4],
          }}
        ></div>
      </div>
      <div className='HeadToHeadTeamPanel-switchButton' onClick={switcheTeamsPosition}>
        <ArrowsClockwiseIcon width={20} height={20} />
      </div>
    </div>
  );
};

export default HeadToHeadTeamPanel;
