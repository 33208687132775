import { IUserLogResponse } from '../Types';
import { axiosInstance } from './axiosInstance';

export const getUserLog = async (token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response: IUserLogResponse = await axiosInstance.get(`/user-activity-log`, {
    headers: headers,
  });
  return response.data;
};
