import { FC } from 'react';

import { useAppSelector } from '../../Redux/store';

interface IProps {
  homeTeam: string;
  awayteam: string;
  topLeft: { home: number; away: number };
  bottomLeft: { home: number; away: number };
  center: { home: number; away: number };
  topRight: { home: number; away: number };
  bottomRight: { home: number; away: number };
  selectedPart: string;
  setSelectedPart: (value: string) => void;
}

const FaceOffMap: FC<IProps> = ({
  topLeft,
  bottomLeft,
  center,
  topRight,
  bottomRight,
  homeTeam,
  awayteam,
  selectedPart,
  setSelectedPart,
}) => {
  const identity = useAppSelector(state => state.login.identity);
  const awayTeam = useAppSelector(state => state.match.value.awayTeam.id);

  const returnBackground = (home: number, away: number) => {
    if (home > away) return identity.teamId === awayTeam ? '#e03131' : '#12b886';
    else if (home < away) return identity.teamId === awayTeam ? '#12b886' : '#e03131';
    else return '#868E96';
  };
  const selectPart = (part: string) => {
    if (selectedPart === part) {
      setSelectedPart('');
    } else {
      setSelectedPart(part);
    }
  };

  return (
    <div className='FaceOffMap'>
      <div className='FaceOffMap-team home'>{homeTeam}</div>
      <div className='FaceOffMap-team away'>{awayteam}</div>
      <div className='FaceOffMap-info'>Zobrazené statistiky za celé střední pásmo</div>
      <svg
        width='744'
        height='360'
        viewBox='0 0 744 360'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M584 1C612.019 1 632.973 1.00078 649.598 2.3591C666.212 3.71646 678.414 6.42448 688.945 11.7904C707.573 21.2818 722.718 36.4269 732.21 55.0549C737.576 65.5861 740.284 77.7883 741.641 94.4016C742.999 111.027 743 131.981 743 160V200C743 228.019 742.999 248.973 741.641 265.598C740.284 282.212 737.576 294.414 732.21 304.945C722.718 323.573 707.573 338.718 688.945 348.21C678.414 353.576 666.212 356.284 649.598 357.641C632.973 358.999 612.019 359 584 359H481L481 1L584 1Z'
          fill='white'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M696 164V196H679.215C674.777 191.718 672 186.123 672 180C672 173.877 674.777 168.282 679.215 164H696Z'
          fill='#0B0D0E'
        />
        <path
          d='M712 175.145V184.857C712 190.464 707.48 195 701.917 195C701.523 195 701.131 194.977 700.74 194.931L695 194.252V165.749L700.74 165.07C706.265 164.417 711.28 168.391 711.931 173.96C711.977 174.353 712 174.749 712 175.145Z'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path
          d='M703 172.859V187.141C703 190.875 699.773 194 695.667 194C695.478 194 695.289 193.993 695.101 193.98L695 193.972V166.025L695.225 166.013C699.325 165.781 702.75 168.722 702.987 172.453C702.996 172.588 703 172.723 703 172.859Z'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path d='M695.5 16.5L695.5 343.5' stroke='#0B0D0E' strokeWidth='2' strokeLinecap='square' />

        <path
          d='M160 1C131.981 1 111.027 1.00078 94.4016 2.3591C77.7883 3.71646 65.5861 6.42448 55.0549 11.7904C36.4269 21.2818 21.2818 36.4269 11.7904 55.0549C6.42448 65.5861 3.71646 77.7883 2.3591 94.4016C1.00078 111.027 1 131.981 1 160L1 200C1 228.019 1.00078 248.973 2.3591 265.598C3.71646 282.212 6.42448 294.414 11.7904 304.945C21.2818 323.573 36.4269 338.718 55.0549 348.21C65.5861 353.576 77.7883 356.284 94.4016 357.641C111.027 358.999 131.981 359 160 359H263L263 1L160 1Z'
          fill='white'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M48 164V196H64.7846C69.2235 191.718 72 186.123 72 180C72 173.877 69.2235 168.282 64.7846 164H48Z'
          fill='#0B0D0E'
        />
        <path
          d='M32 175.145V184.857C32 190.464 36.5196 195 42.0833 195C42.4766 195 42.8695 194.977 43.26 194.931L49 194.252V165.749L43.26 165.07C37.7346 164.417 32.7201 168.391 32.069 173.96C32.0231 174.353 32 174.749 32 175.145Z'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path
          d='M41 172.859V187.141C41 190.875 44.2272 194 48.3333 194C48.5221 194 48.7107 193.993 48.8989 193.98L49 193.972V166.025L48.775 166.013C44.6746 165.781 41.2497 168.722 41.0129 172.453C41.0043 172.588 41 172.723 41 172.859Z'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path d='M48.5 16.5L48.5 343.5' stroke='#0B0D0E' strokeWidth='2' strokeLinecap='square' />
        <rect
          x='479'
          y='1'
          width='358'
          height='214'
          transform='rotate(90 479 1)'
          fill='white'
          stroke='#0B0D0E'
          strokeWidth='2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M374 0L374 360H370L370 0L374 0Z'
          fill='#0B0D0E'
        />
        <circle cx='287' cy='92' r='5' transform='rotate(90 287 92)' fill='#0B0D0E' />
        <circle cx='287' cy='268' r='5' transform='rotate(90 287 268)' fill='#0B0D0E' />
        <circle cx='457' cy='92' r='5' transform='rotate(90 457 92)' fill='#0B0D0E' />
        <circle cx='457' cy='268' r='5' transform='rotate(90 457 268)' fill='#0B0D0E' />

        {/* Top Left start */}
        <g
          className={`FaceOffMap-part${
            selectedPart && selectedPart !== 'topLeft' ? ' disabled' : ''
          }`}
          onClick={() => selectPart('topLeft')}
        >
          <circle
            cx='56'
            cy='56'
            r='55'
            transform='matrix(0 1 1 0 69 36)'
            stroke={returnBackground(topLeft.home, topLeft.away)}
            strokeWidth='2'
            className='outerCircle'
          />
          <circle
            cx='56'
            cy='56'
            r='40'
            transform='matrix(0 1 1 0 69 36)'
            fill={returnBackground(topLeft.home, topLeft.away)}
            className='innerCircle'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M112 30V38H114V30H112ZM136 30V38L138 38V30L136 30ZM112 146L112 154H114V146H112ZM136 146V154H138V146H136Z'
            fill={returnBackground(topLeft.home, topLeft.away)}
          />
          <text x='125' y='92' textAnchor='middle' alignmentBaseline='middle' fill='white'>
            {topLeft.home}:{topLeft.away}
          </text>
        </g>
        {/* Top Left end */}

        {/* Bottom Left start */}
        <g
          className={`FaceOffMap-part${
            selectedPart && selectedPart !== 'bottomLeft' ? ' disabled' : ''
          }`}
          onClick={() => selectPart('bottomLeft')}
        >
          <circle
            cx='56'
            cy='56'
            r='55'
            transform='matrix(0 1 1 0 69 212)'
            stroke={returnBackground(bottomLeft.home, bottomLeft.away)}
            strokeWidth='2'
            className='outerCircle'
          />
          <circle
            cx='56'
            cy='56'
            r='40'
            transform='matrix(0 1 1 0 69 212)'
            fill={returnBackground(bottomLeft.home, bottomLeft.away)}
            className='innerCircle'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M112 206V214H114V206H112ZM136 206V214H138V206H136ZM112 322L112 330H114V322H112ZM136 322V330H138V322H136Z'
            fill={returnBackground(bottomLeft.home, bottomLeft.away)}
          />
          <text x='125' y='268' textAnchor='middle' alignmentBaseline='middle' fill='white'>
            {bottomLeft.home}:{bottomLeft.away}
          </text>
        </g>
        {/* Bottom Left end */}

        {/* Center start */}
        <g
          className={`FaceOffMap-part${
            selectedPart && selectedPart !== 'center' ? ' disabled' : ''
          }`}
          onClick={() => selectPart('center')}
        >
          <circle
            cx='372'
            cy='180'
            r='55'
            transform='rotate(90 372 180)'
            stroke={returnBackground(center.home, center.away)}
            strokeWidth='2'
            className='outerCircle'
          />
          <circle
            cx='372'
            cy='180'
            r='40'
            transform='rotate(90 372 180)'
            fill={returnBackground(center.home, center.away)}
            className='innerCircle'
          />
          <text x='372' y='180' textAnchor='middle' alignmentBaseline='middle' fill='white'>
            {center.home}:{center.away}
          </text>
        </g>
        {/* Center end */}

        {/* Top Right start */}
        <g
          className={`FaceOffMap-part${
            selectedPart && selectedPart !== 'topRight' ? ' disabled' : ''
          }`}
          onClick={() => selectPart('topRight')}
        >
          <circle
            cx='619'
            cy='92'
            r='55'
            transform='rotate(90 619 92)'
            stroke={returnBackground(topRight.home, topRight.away)}
            strokeWidth='2'
            className='outerCircle'
          />
          <circle
            cx='619'
            cy='92'
            r='40'
            transform='rotate(90 619 92)'
            fill={returnBackground(topRight.home, topRight.away)}
            className='innerCircle'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M632 30V38H630V30H632ZM608 30V38H606V30H608ZM632 146V154H630V146H632ZM608 146V154H606V146H608Z'
            fill={returnBackground(topRight.home, topRight.away)}
          />
          <text x='619' y='92' textAnchor='middle' alignmentBaseline='middle' fill='white'>
            {topRight.home}:{topRight.away}
          </text>
        </g>
        {/* Top Right end */}

        {/* Bottom Right start */}
        <g
          className={`FaceOffMap-part${
            selectedPart && selectedPart !== 'bottomRight' ? ' disabled' : ''
          }`}
          onClick={() => selectPart('bottomRight')}
        >
          <circle
            cx='619'
            cy='268'
            r='55'
            transform='rotate(90 619 268)'
            stroke={returnBackground(bottomRight.home, bottomRight.away)}
            strokeWidth='2'
            className='outerCircle'
          />
          <circle
            cx='619'
            cy='268'
            r='40'
            transform='rotate(90 619 268)'
            fill={returnBackground(bottomRight.home, bottomRight.away)}
            className='innerCircle'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M632 206V214H630V206H632ZM608 206V214H606V206H608ZM632 322V330H630V322H632ZM608 322V330H606V322H608Z'
            fill={returnBackground(bottomRight.home, bottomRight.away)}
          />
          <text x='619' y='268' textAnchor='middle' alignmentBaseline='middle' fill='white'>
            {bottomRight.home}:{bottomRight.away}
          </text>
        </g>
        {/* Bottom Right end */}
      </svg>
    </div>
  );
};

export default FaceOffMap;
