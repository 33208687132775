import {
  IExtendedFormation,
  IExtendedTeamFormation,
  IFormation,
  IFormationsTeam,
  IMatch,
  IPlayer,
  IShot,
  IShotsByTeam,
} from '../Types';
import { getPlayerById, getThirdsInterval } from './matches';
import { getFormationPlayers, getShotsOfPickedPlayers } from './players';
import { getPlayerShots } from './shots';

export const prepareTeamFormations = (
  data: IFormationsTeam,
  roster: IPlayer[],
  shots: IShot[],
): IExtendedTeamFormation => {
  const forwards = data.forwards
    .sort((a: IFormation, b: IFormation) => b.metrics.TOI - a.metrics.TOI)
    .splice(0, 4);
  const backwards = data.backwards
    .sort((a: IFormation, b: IFormation) => b.metrics.TOI - a.metrics.TOI)
    .splice(0, 3);

  return {
    forwards: [...forwards.map(formation => prepareFormation(formation, roster, shots))],
    backwards: [...backwards.map(formation => prepareFormation(formation, roster, shots))],
  };
};

export const prepareFormation = (
  formation: IFormation,
  roster: IPlayer[],
  shots: IShot[],
): IExtendedFormation => {
  const players = [...formation.players.map(item => getPlayerById(item, roster)!)];
  return {
    players: [
      ...players.map(player => ({
        ...player,
        shots: getPlayerShots(player.id, shots),
      })),
    ],
    metrics: formation.metrics,
  };
};

export const filterShotsBySelectedThird = (third: string, shots: IShotsByTeam): IShotsByTeam => {
  if (third === 'All') return shots;

  const { home, away } = shots;
  const { start, end } = getThirdsInterval(third);
  const filteredShots: IShotsByTeam = {
    home: filterShotsByGivenTime(home, start, end),
    away: filterShotsByGivenTime(away, start, end),
  };

  return filteredShots;
};

const filterShotsByGivenTime = (shots: IShot[], startTime: number, endTime: number): IShot[] =>
  [...shots].filter(shot => shot.time - 1 > startTime && shot.time < endTime);

export const getShotsOfFormation = (
  formation: IFormation,
  match: IMatch,
  teamActive: string,
  teamShots: IShotsByTeam,
): IShotsByTeam => {
  let players = getFormationPlayers(
    formation.players,
    teamActive === match.homeTeam.shortcut ? match.homeTeam.roster : match.awayTeam.roster,
  );
  return getShotsOfPickedPlayers(teamShots.home, teamShots.away, players, []);
};
