import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IVideo } from '../Types';

interface IModal {
  state: boolean;
  type: 'screen' | 'video';
  video?: IVideo;
}

const bugReportInitialState: IModal = {
  state: false,
  type: 'screen',
  video: undefined,
};

export const bugReportSlice = createSlice({
  name: 'bugReport',
  initialState: bugReportInitialState,
  reducers: {
    handleBugReportOpen: (
      _,
      action: PayloadAction<{ type: 'screen' | 'video'; video?: IVideo }>,
    ) => {
      return {
        state: true,
        type: action.payload.type,
        video: action.payload.video,
      };
    },
    handleBugReportClose: () => {
      return bugReportInitialState;
    },
  },
});

export const { handleBugReportOpen, handleBugReportClose } = bugReportSlice.actions;
export default bugReportSlice.reducer;
