import { FC } from 'react';

interface IProps {
  jersey: string;
  name: string;
  surname: string;
  shots: number;
  goals: number;
}

const FormationsStatsPlayer: FC<IProps> = ({ jersey, name, surname, shots, goals }) => {
  return (
    <div className='FormationsStats-player'>
      <div className='FormationsStats-player--number'>{jersey}</div>
      <p>{name}</p>
      <p>
        <span>{surname}</span>
      </p>
      <p className='FormationsStats-player--stats'>
        S<span>{shots}</span>/G<span>{goals}</span>
      </p>
    </div>
  );
};

export default FormationsStatsPlayer;
