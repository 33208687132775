import { FC } from 'react';

interface IProps {
  active: boolean;
  onClick: () => void;
  label?: string;
}

const Switch: FC<IProps> = ({ active, onClick, label }) => {
  return (
    <div className={`Switch${active ? ' active' : ''}`} onClick={onClick}>
      <div className='Switch-outer'>
        <div className='Switch-inner'></div>
      </div>
      {label && <p className='Switch-label'>{label}</p>}
    </div>
  );
};

export default Switch;
