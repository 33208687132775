import {
  calculateCount,
  calculateCountInterval,
  calculateCountMultiple,
  calculateSum,
  formatFaceOffValue,
  getGoalkeeperShots,
  getGoalkeeperShotsByResult,
  getPercent,
  getPlayerBlockedShotVideos,
  getPlayerBlockedShotsCount,
  getPlayerExpectedGoals,
  getPlayerFaceOffCountWinner,
  getPlayerFaceOffVideos,
  getPlayerHighDangerShotVideos,
  getPlayerHighDangerShotsCount,
  getPlayerLowDangerShotVideos,
  getPlayerLowDangerShotsCount,
  getPlayerMissedShotVideos,
  getPlayerMissedShotsCount,
  getPlayerShotOnGoalCount,
  getPlayerShotOnGoalVideos,
  getPlayerShotVideos,
  getPlayerShotsCount,
  getPlayerSlotShotVideos,
  getPlayerSlotShotsCount,
  getTeamFaceOffWinner,
  getTeamShots,
  sortByFaceOffValue,
} from '.';
import { IStatsBox } from '../Components/03-Partials/StatsBox';
import { IStatsBoxSimple } from '../Components/03-Partials/StatsBoxSimple';
import { IDetailData, IFaceOff, IMatch, IPlayer, IShot, IVideo } from '../Types';

const defaultCompareFn = (a: IDetailData, b: IDetailData) => b.value - a.value;

export const prepareStatsData = (
  match: IMatch,
  players: IPlayer[],
  from: any[],
  getValue: (playerId: string, array: any[]) => number,
  getVideos?: (player: IPlayer, array: any[], match: IMatch) => IVideo[],
  getFormattedValue?: (playerId: string, array: any[]) => string,
  compareFn: (a: IDetailData, b: IDetailData) => number = defaultCompareFn,
) => {
  const data = [
    ...players
      .filter(player => player.position !== 'goalkeeper')
      .map(player => ({
        ...player,
        value: getValue(player.id, from),
        formattedValue: getFormattedValue ? getFormattedValue(player.id, from) : undefined,
        videos: getVideos ? getVideos(player, from, match) : [],
      })),
  ];
  return data.sort(compareFn);
};

export const prepareOverviewBigStats = (match: IMatch): IStatsBox[] => {
  const shots = match.shot;
  const homePlayers = match.homeTeam.roster;
  const awayPlayers = match.awayTeam.roster;
  const homeShots = getTeamShots(homePlayers, shots);
  const awayShots = getTeamShots(awayPlayers, shots);

  const shotStats = {
    title: 'střely',
    homeStats: homeShots.length,
    awayStats: awayShots.length,
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerShotsCount,
      getPlayerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerShotsCount,
      getPlayerShotVideos,
    ),
  };

  const expectedGoalsStats = {
    title: 'očekávané góly',
    fixed: 2,
    homeStats: calculateSum(homeShots, 'xG'),
    awayStats: calculateSum(awayShots, 'xG'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerExpectedGoals,
      getPlayerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerExpectedGoals,
      getPlayerShotVideos,
    ),
  };

  return [shotStats, expectedGoalsStats];
};

export const prepareOverviewSmallStats = (match: IMatch): IStatsBox[] => {
  const shots = match.shot;
  const homePlayers = match.homeTeam.roster;
  const awayPlayers = match.awayTeam.roster;
  const homeShots = getTeamShots(homePlayers, shots);
  const awayShots = getTeamShots(awayPlayers, shots);

  const shotOnGoalStats = {
    title: 'střely na branku',
    homeStats: calculateCountMultiple(homeShots, 'result', ['saved', 'goal']),
    awayStats: calculateCountMultiple(awayShots, 'result', ['saved', 'goal']),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerShotOnGoalCount,
      getPlayerShotOnGoalVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerShotOnGoalCount,
      getPlayerShotOnGoalVideos,
    ),
  };

  const slotStats = {
    title: 'střely ze slotu',
    homeStats: calculateCount(homeShots, 'inSlot', true),
    awayStats: calculateCount(awayShots, 'inSlot', true),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerSlotShotsCount,
      getPlayerSlotShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerSlotShotsCount,
      getPlayerSlotShotVideos,
    ),
  };

  const missedShotsStat = {
    title: 'střely mimo',
    homeStats: calculateCount(homeShots, 'result', 'missed'),
    awayStats: calculateCount(awayShots, 'result', 'missed'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerMissedShotsCount,
      getPlayerMissedShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerMissedShotsCount,
      getPlayerMissedShotVideos,
    ),
  };

  const hightDangerShotsStats = {
    title: 'Střely s vysokou nebezpečností',
    homeStats: calculateCount(homeShots, 'shotDanger', 'HD'),
    awayStats: calculateCount(awayShots, 'shotDanger', 'HD'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerHighDangerShotsCount,
      getPlayerHighDangerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerHighDangerShotsCount,
      getPlayerHighDangerShotVideos,
    ),
  };

  const blockedShotsStat = {
    title: 'zablokované střely',
    homeStats: calculateCount(homeShots, 'result', 'deflected'),
    awayStats: calculateCount(awayShots, 'result', 'deflected'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerBlockedShotsCount,
      getPlayerBlockedShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerBlockedShotsCount,
      getPlayerBlockedShotVideos,
    ),
  };

  const lowDangerShotsStats = {
    title: 'Střely s nízkou nebezpečností',
    homeStats: calculateCount(homeShots, 'shotDanger', 'LD'),
    awayStats: calculateCount(awayShots, 'shotDanger', 'LD'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      shots,
      getPlayerLowDangerShotsCount,
      getPlayerLowDangerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      shots,
      getPlayerLowDangerShotsCount,
      getPlayerLowDangerShotVideos,
    ),
  };

  const faceOffStats = prepareFaceOffCountStats(match, match.faceOff);

  return [
    shotOnGoalStats,
    slotStats,
    missedShotsStat,
    hightDangerShotsStats,
    blockedShotsStat,
    lowDangerShotsStats,
    faceOffStats,
  ];
};

export const prepareFaceOffCountStats = (match: IMatch, faceOffs: IFaceOff[]) => {
  return {
    title: 'Vhazování',
    homeStats: getTeamFaceOffWinner(match.homeTeam.roster, faceOffs).length,
    awayStats: getTeamFaceOffWinner(match.awayTeam.roster, faceOffs).length,
    homePlayers: prepareStatsData(
      match,
      match.homeTeam.roster,
      faceOffs,
      getPlayerFaceOffCountWinner,
      getPlayerFaceOffVideos,
      formatFaceOffValue,
      sortByFaceOffValue,
    ),
    awayPlayers: prepareStatsData(
      match,
      match.awayTeam.roster,
      faceOffs,
      getPlayerFaceOffCountWinner,
      getPlayerFaceOffVideos,
      formatFaceOffValue,
      sortByFaceOffValue,
    ),
  };
};

export const prepareGoalkeepersStats = (
  goalkeeperHome: string,
  goalkeeperAway: string,
  shots: IShot[],
) => {
  const home = getGoalkeeperShotsByResult(goalkeeperHome, shots, 'saved');
  const away = getGoalkeeperShotsByResult(goalkeeperAway, shots, 'saved');
  const homeAll = getGoalkeeperShots(goalkeeperHome, shots);
  const awayAll = getGoalkeeperShots(goalkeeperAway, shots);
  const homeGoals = getGoalkeeperShotsByResult(goalkeeperHome, shots, 'goal');
  const awayGoals = getGoalkeeperShotsByResult(goalkeeperAway, shots, 'goal');

  const xSvStats = {
    title: 'Góly chycené nad očekávání',
    homeStats: (home.length - calculateSum(homeAll, 'xSv')).toFixed(2),
    awayStats: (away.length - calculateSum(awayAll, 'xSv')).toFixed(2),
  };

  const intervationStats = {
    title: 'Zákroky',
    homeStats: home.length,
    awayStats: away.length,
  };

  const successStats = {
    title: 'Úspěšnost zákroků',
    homeStats: getPercent(home.length, home.length + homeGoals.length),
    awayStats: getPercent(away.length, away.length + awayGoals.length),
  };

  const goalStats = {
    title: 'obdržené góly',
    homeStats: homeGoals.length,
    awayStats: awayGoals.length,
  };

  const slotStats = {
    title: 'Zákroky ze slotu',
    homeStats: calculateCount(home, 'inSlot', true),
    awayStats: calculateCount(away, 'inSlot', true),
  };

  return [xSvStats, intervationStats, successStats, goalStats, slotStats];
};

export const prepareFaceOffSmallStats = (
  match: IMatch,
  faceOffs: IFaceOff[],
): IStatsBoxSimple[] => {
  const home = getTeamFaceOffWinner(match.homeTeam.roster, faceOffs);
  const away = getTeamFaceOffWinner(match.awayTeam.roster, faceOffs);

  const thirds1Stats = {
    title: '1. třetina',
    homeStats: calculateCountInterval(home, 'time', 0, 1200, 1),
    awayStats: calculateCountInterval(away, 'time', 0, 1200, 1),
  };

  const thirds2Stats = {
    title: '2. třetina',
    homeStats: calculateCountInterval(home, 'time', 1200, 2400, 1),
    awayStats: calculateCountInterval(away, 'time', 1200, 2400, 1),
  };

  const thirds3Stats = {
    title: '3. třetina',
    homeStats: calculateCountInterval(home, 'time', 2400, 3600, 1),
    awayStats: calculateCountInterval(away, 'time', 2400, 3600, 1),
  };

  const OvertimeStats = {
    title: 'Prodloužení',
    homeStats: calculateCountInterval(home, 'time', 3600, match.length, 1),
    awayStats: calculateCountInterval(away, 'time', 3600, match.length, 1),
  };

  return [thirds1Stats, thirds2Stats, thirds3Stats, OvertimeStats];
};

export const prepareShotsSmallStats = (
  home: IShot[],
  away: IShot[],
  match: IMatch,
): IStatsBox[] => {
  const homePlayers = match.homeTeam.roster;
  const awayPlayers = match.awayTeam.roster;

  const shotsStats = {
    title: 'střely',
    homeStats: home.length,
    awayStats: away.length,
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      home,
      getPlayerShotsCount,
      getPlayerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      away,
      getPlayerShotsCount,
      getPlayerShotVideos,
    ),
  };

  const xgStats = {
    title: 'očekávané góly',
    fixed: 2,
    homeStats: calculateSum(home, 'xG'),
    awayStats: calculateSum(away, 'xG'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      home,
      getPlayerExpectedGoals,
      getPlayerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      away,
      getPlayerExpectedGoals,
      getPlayerShotVideos,
    ),
  };

  const slotStats = {
    title: 'střely ze slotu',
    homeStats: calculateCount(home, 'inSlot', true),
    awayStats: calculateCount(away, 'inSlot', true),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      home,
      getPlayerSlotShotsCount,
      getPlayerSlotShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      away,
      getPlayerSlotShotsCount,
      getPlayerSlotShotVideos,
    ),
  };

  const highDangerStats = {
    title: 'střely s vysokou nebezpečností',
    homeStats: calculateCount(home, 'shotDanger', 'HD'),
    awayStats: calculateCount(away, 'shotDanger', 'HD'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      home,
      getPlayerHighDangerShotsCount,
      getPlayerHighDangerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      away,
      getPlayerHighDangerShotsCount,
      getPlayerHighDangerShotVideos,
    ),
  };

  const lowDangerStats = {
    title: 'střely s nízkou nebezpečností',
    homeStats: calculateCount(home, 'shotDanger', 'LD'),
    awayStats: calculateCount(away, 'shotDanger', 'LD'),
    homePlayers: prepareStatsData(
      match,
      homePlayers,
      home,
      getPlayerLowDangerShotsCount,
      getPlayerLowDangerShotVideos,
    ),
    awayPlayers: prepareStatsData(
      match,
      awayPlayers,
      away,
      getPlayerLowDangerShotsCount,
      getPlayerLowDangerShotVideos,
    ),
  };

  return [shotsStats, xgStats, slotStats, highDangerStats, lowDangerStats];
};
