export const VIDEO_HEAD_HTML_DEFAULT_STRING = `
<head>
  <meta http-equiv="content-type" content="text/html; charset=utf-8" />
  <title></title>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
    crossorigin="anonymous"
  />
  <script
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
    crossorigin="anonymous"
  ></script>
  <style type="text/css">
    body {
        padding: 0;
        margin: 0;
    }
  </style>
</head>`;

export const VIDEO_DOCUMENT_WRITE_HTML_DEFAULT_STRING = `
  <script type="text/javascript">
    document.write(unescape("%3Cscript src='https://play.elh.livebox.cz/js/LBXPlayer.js?_tok"+Math.floor(Math.random()*1073741824)+"__="+Math.floor(Math.random()*1073741824)+"' type='text/javascript'%3E%3C/script%3E"));
  </script>
`;
