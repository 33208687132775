import { IGateZone, IPlayer, IShot, IShotResult } from '../Types';
import { calculateCount } from './common';

export const getTeamGoalkeepers = (roster: IPlayer[], shots: IShot[]) => {
  return [
    ...roster
      .filter(player => player.position === 'goalkeeper')
      .sort(
        (a: IPlayer, b: IPlayer) =>
          getGoalkeeperShots(b.id, shots).length - getGoalkeeperShots(a.id, shots).length,
      ),
  ];
};

export const getTeamGoalkeepersData = (goalkeepers: IPlayer[], shots: IShot[]) => {
  let res: any = {};
  goalkeepers.forEach(goalkeeper => {
    res[goalkeeper.id] = prepareGoalkeeperData(goalkeeper.id, shots);
  });

  return res;
};

export const getGoalkeeperShots = (goalkeeperId: string, shots: IShot[]) => {
  return [...shots.filter(shot => shot.goalkeeperId === goalkeeperId)];
};

export const getGoalkeeperShotsByResult = (
  goalkeeperId: string,
  shots: IShot[],
  result: IShotResult,
) => {
  return [...getGoalkeeperShots(goalkeeperId, shots).filter(shot => shot.result === result)];
};

export const prepareGoalkeeperData = (goalkeeperId: string, shots: IShot[]) => {
  const goalkeeperShots = getGoalkeeperShots(goalkeeperId, shots);
  return [
    ...getGateZones().map(zone => ({
      ...prepareGateZone(goalkeeperShots, zone),
    })),
  ];
};

export const prepareGateZone = (shots: IShot[], zone: IGateZone) => {
  const zoneShots = shots.filter(shot => shot.gateZone === zone);
  return {
    goals: calculateCount(zoneShots, 'result', 'goal'),
    outOf: zoneShots.length,
    ...prepareZonePosition(zone),
    zone,
  };
};

export const prepareZonePosition = (zone: IGateZone) => {
  switch (zone) {
    case 'ur':
      return { top: '17%', left: '15%' };
    case 'ul':
      return { top: '17%', right: '15%' };
    case 'br':
      return { bottom: '22%', left: '10%' };
    case 'bl':
      return { bottom: '22%', right: '10%' };
    case 'ce':
      return { top: '41%', left: '44.75%' };
    default:
      return { bottom: '9%', left: '42%' };
  }
};

const getGateZones = (): IGateZone[] => ['ul', 'ur', 'ce', 'bl', 'br', 'fh'];
