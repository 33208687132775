import { FC, useCallback, useEffect, useState } from 'react';

import HeadToHeadTeamPanel from '../03-Partials/HeadToHeadTeamPanel';
import PieChartWrapper from '../04-Micros/PieChartWrapper';
import SelectTab from '../04-Micros/SelectTab';
import { useAppSelector } from '../../Redux/store';
import { IIceTimeOpponent, IIceTimePlayer } from '../../Types';
import {
  comparePlayersByLineup,
  formatSeconds,
  getMaxIceTime,
  getPlayerTeam,
  getPlayersRelation,
  getToiWithPlayer,
} from '../../Utils';

interface IProps {
  homeTeam: string;
  awayTeam: string;
  home: IIceTimePlayer[];
  away: IIceTimePlayer[];
}

const HeadToHeadCharts: FC<IProps> = ({ homeTeam, awayTeam, home, away }) => {
  const match = useAppSelector(state => state.match.value);
  const [homePosition, setHomePosition] = useState<'top' | 'left'>('left');
  const [awayPosition, setAwayPosition] = useState<'top' | 'left'>('top');
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const [size, setSize] = useState<number | null>(null);
  const [maxTOI, setMaxTOI] = useState<number>(0);

  useEffect(() => {
    if (home.length > 0) {
      setMaxTOI(getMaxIceTime(home));
    }
  }, [home]);

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setSize(node.getBoundingClientRect().width);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [home],
  );

  const selectPlayer = (playerId: string) => {
    if (selectedPlayer === playerId) setSelectedPlayer('');
    else setSelectedPlayer(playerId);
  };

  const getChartsSize = () => home.length * 40 - 8;

  const prepareToi = (player: IIceTimePlayer | IIceTimeOpponent): number => {
    if (selectedPlayer && selectedPlayer !== player.id) {
      const toi = getToiWithPlayer(
        player.id,
        selectedPlayer,
        getPlayerTeam(selectedPlayer, match) === 'home' ? home : away,
        getPlayersRelation(player.id, selectedPlayer, match),
      );
      return toi ? toi : 0;
    }
    return player.metrics ? player.metrics.TOI : 0;
  };

  return (
    <div className='HeadToHeadCharts' ref={measuredRef}>
      <HeadToHeadTeamPanel
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        homePosition={homePosition}
        awayPosition={awayPosition}
        setHomePosition={setHomePosition}
        setAwayPosition={setAwayPosition}
      />
      <div className={`HeadToHeadCharts-players ${homePosition}`}>
        {home.map(player => (
          <SelectTab
            key={player.id}
            valueLeft={`${player.jersey} ${player.surname}`}
            valueRight={formatSeconds(prepareToi(player))}
            active={selectedPlayer === player.id}
            onClick={() => selectPlayer(player.id)}
            vertical={homePosition === 'top'}
            highlight={size ? size : 0}
            disabled={!player.metrics}
          />
        ))}
      </div>
      <div className={`HeadToHeadCharts-players ${awayPosition}`}>
        {away.map(player => (
          <SelectTab
            key={player.id}
            valueLeft={`${player.jersey} ${player.surname}`}
            valueRight={formatSeconds(prepareToi(player))}
            active={selectedPlayer === player.id}
            onClick={() => selectPlayer(player.id)}
            vertical={awayPosition === 'top'}
            highlight={size ? size : 0}
            disabled={!player.metrics}
          />
        ))}
      </div>
      <div
        className={`HeadToHeadCharts-charts ${homePosition}`}
        style={{
          maxWidth: getChartsSize(),
          maxHeight: getChartsSize(),
        }}
      >
        {home.map((player, i) => {
          if (!player.opponents.length) {
            return home.map((_, j) => <PieChartWrapper key={`${i}${j}`} />);
          }

          return [...player.opponents]
            .sort(comparePlayersByLineup)
            .map((opponent, j) => (
              <PieChartWrapper
                key={`${i}${j}`}
                valueHome={opponent.metrics?.CF}
                valueGuest={opponent.metrics?.CA}
                toi={opponent.metrics?.TOI}
                outOf={maxTOI}
              />
            ));
        })}
      </div>
    </div>
  );
};

export default HeadToHeadCharts;
