import { Button, Drawer } from '@mui/material';
import parse from 'html-react-parser';
import { FC } from 'react';

import { ArrowCircleRightIcon } from '../../Icons';
import { useAppSelector } from '../../Redux/store';
import { PAGE_TIPS, PAGE_TITLE } from '../../Utils';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const Sidebar: FC<IProps> = ({ open, onClose }) => {
  const tab = useAppSelector(state => state.navigationTab.value);

  return (
    <Drawer className='Sidebar' anchor='right' open={open} onClose={onClose}>
      <div className='Sidebar-header'>
        <Button variant='text' startIcon={<ArrowCircleRightIcon />} onClick={onClose}>
          Zavřít nápovědu
        </Button>
      </div>
      <div className='Sidebar-content'>
        <h1>{PAGE_TITLE[tab]}</h1>
        <p>{parse(PAGE_TIPS[tab])}</p>
      </div>
    </Drawer>
  );
};

export default Sidebar;
