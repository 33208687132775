import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { logScreen } from '../../Api';
import {
  ChartBarActiveIcon,
  ChartBarIcon,
  FormationsIcon,
  FormationsIconActive,
  GoalkeepersIcon,
  GoalkeepersIconActive,
  HeadToHeadIcon,
  HeadToHeadIconActive,
  IceTimeIcon,
  IceTimeIconActive,
  MatchesIcon,
  MatchesIconActive,
  OverviewIcon,
  OverviewIconActive,
  ShotsIcon,
  ShotsIconActive,
  ThrowIcon,
  ThrowIconActive,
} from '../../Icons';
import { useAppSelector } from '../../Redux/store';
import { TNavigateUrl } from '../../Types/navigation';

const MainNavigation = () => {
  const navigate = useNavigate();
  const tabValue = useAppSelector(state => state.navigationTab.value);
  const token = useAppSelector(state => state.login.token.access_token);

  const handleTabValueChange = (_: SyntheticEvent, newValue: TNavigateUrl) => {
    navigate(`/${newValue}`);
    logScreen(token, newValue);
  };

  return (
    <nav className='MainNavigation'>
      <TabList onChange={handleTabValueChange} variant='fullWidth'>
        <Tab
          icon={tabValue === 'matches' ? <MatchesIconActive /> : <MatchesIcon />}
          label='Zápasy'
          value='matches'
        />
        <Tab
          icon={tabValue === 'overview' ? <OverviewIconActive /> : <OverviewIcon />}
          label='Přehled'
          value='overview'
        />
        <Tab
          icon={tabValue === 'shots' ? <ShotsIconActive /> : <ShotsIcon />}
          label='Střely'
          value='shots'
        />
        <Tab
          icon={tabValue === 'formations' ? <FormationsIconActive /> : <FormationsIcon />}
          label='Srovnání formací'
          value='formations'
        />
        <Tab
          icon={tabValue === 'goalkeepers' ? <GoalkeepersIconActive /> : <GoalkeepersIcon />}
          label='Brankáři'
          value='goalkeepers'
        />
        <Tab
          icon={tabValue === 'faceOff' ? <ThrowIconActive /> : <ThrowIcon />}
          label='Vhazování'
          value='faceOff'
        />
        <Tab
          icon={tabValue === 'iceTime' ? <IceTimeIconActive /> : <IceTimeIcon />}
          label='IceTime'
          value='iceTime'
        />
        <Tab
          icon={tabValue === 'h2h' ? <HeadToHeadIconActive /> : <HeadToHeadIcon />}
          label='Kdo proti komu'
          value='h2h'
        />
        <Tab
          icon={tabValue === 'momentum' ? <ChartBarActiveIcon /> : <ChartBarIcon />}
          label='Vývoj zápasu'
          value='momentum'
        />
      </TabList>
    </nav>
  );
};

export default MainNavigation;
