import { IFilter, IGameState, IMatch, IOvertimeInfo, IState } from '../Types';
import { getIntervalIntersection } from './common';
import { getTeamFaceOffWinner } from './faceOff';
import {
  gameStateIncludes,
  getGameStates,
  getThirdsInterval,
  isInTime,
  isStatePowerPlay,
} from './matches';
import { getTeamShots } from './shots';

export const getAllFilter = () => {
  return { label: 'Vše', value: 'All' };
};

export const getGameStateFilters = (
  powerPlayLabel: 'PP' | 'SH' = 'PP',
  showPowerPlay: boolean = true,
) => {
  let common: IFilter[] = [
    getAllFilter(),
    {
      label: '5v5',
      value: '5:5',
    },
  ];
  if (showPowerPlay) {
    common = [...common, { label: powerPlayLabel, value: 'PP' }];
  }

  return common;
};

export const filterByGameState = (match: IMatch, value: string) => {
  const states = getGameStates(value as IState);
  const homeStates = [...match.homeTeam.gameState.filter(state => states.includes(state.state))];
  const awayStates = [...match.awayTeam.gameState.filter(state => states.includes(state.state))];

  const shots = [
    ...getTeamShots(match.homeTeam.roster, match.shot).filter(shot =>
      gameStateIncludes(homeStates, shot.time),
    ),
    ...getTeamShots(match.awayTeam.roster, match.shot).filter(shot =>
      gameStateIncludes(awayStates, shot.time),
    ),
  ];

  const faceOffs = [
    ...getTeamFaceOffWinner(match.homeTeam.roster, match.faceOff).filter(faceOff =>
      gameStateIncludes(homeStates, faceOff.time),
    ),
    ...getTeamFaceOffWinner(match.awayTeam.roster, match.faceOff).filter(faceOff =>
      gameStateIncludes(awayStates, faceOff.time),
    ),
  ];

  return { ...match, shot: shots, faceOff: faceOffs };
};

export const getThirdsFilters = (
  matchLength: number,
  overtimeInfo: IOvertimeInfo,
  disbaleAll?: boolean,
) => {
  const commonThirds: IFilter[] = [
    {
      label: '1. tř',
      value: '1',
    },
    {
      label: '2. tř',
      value: '2',
      disabled: matchLength < 1200,
    },
    {
      label: '3. tř',
      value: '3',
      disabled: matchLength < 2400,
    },
  ];
  const thirdsWithOvertime = [...commonThirds, ...getThirdsWithOvertime(matchLength, overtimeInfo)];

  if (!disbaleAll) return [getAllFilter(), ...thirdsWithOvertime];

  return thirdsWithOvertime;
};

export const getThirdsWithOvertime = (
  matchLength: number,
  overtimeInfo: IOvertimeInfo,
): IFilter[] => {
  if (overtimeInfo.overtimeLength === 300 || overtimeInfo.overtimeCount === 1) {
    const overtimeArray: IFilter[] = [
      {
        label: 'Prod.',
        value: 'overtime',
        disabled: matchLength <= 3600,
      },
    ];
    return overtimeArray;
  }

  const numberOfOT = Math.floor((matchLength / 60 - 60) / (overtimeInfo.overtimeLength / 60));
  const overtimeArray = Array.from({
    length: numberOfOT + 1,
  }).reduce<IFilter[]>((acc, _, i) => {
    acc.push({
      label: `Prod.${i + 1}`,
      value: `overtime${i + 1}`,
    });
    return acc;
  }, []);

  return overtimeArray;
};

export const getThirdsFilterFromTime = (time: number, filters: IFilter[]) => {
  const filter = filters.find(filter => {
    const { start, end } = getThirdsInterval(filter.value);
    return isInTime(start, end, time);
  });
  return filter ? filter.value : '';
};

export const filterByThirds = (match: IMatch, value: string) => {
  const { start, end } = getThirdsInterval(value);

  const shots = [...match.shot.filter(shot => isInTime(start, end, shot.time))];
  const faceOffs = [...match.faceOff.filter(faceOff => isInTime(start, end, faceOff.time + 1))];

  return { ...match, shot: shots, faceOff: faceOffs };
};

export const getPowerPlayByThirds = (
  homeStates: IGameState[],
  awayStates: IGameState[],
  value: string,
) => {
  const { start, end } = getThirdsInterval(value);

  const home = homeStates
    .filter(state => isStatePowerPlay(state.state))
    .reduce((accumulator, object) => {
      return (
        accumulator +
        getIntervalIntersection({ start: object.start, end: object.end }, { start, end })
      );
    }, 0);
  const away = awayStates
    .filter(state => isStatePowerPlay(state.state))
    .reduce((accumulator, object) => {
      return (
        accumulator +
        getIntervalIntersection({ start: object.start, end: object.end }, { start, end })
      );
    }, 0);

  return { home, away };
};

export const getTeamFilters = (match: IMatch) => {
  return [
    {
      label: match.homeTeam.shortcut,
      value: match.homeTeam.shortcut,
    },
    {
      label: match.awayTeam.shortcut,
      value: match.awayTeam.shortcut,
    },
  ];
};

export const getFormationTypeFilters = () => {
  return [
    {
      label: '5v5 útoky',
      value: 'forwards',
    },
    {
      label: '5v5 obrany',
      value: 'backwards',
    },
  ];
};

export const getBugReportFilters = (type: 'screen' | 'video') => {
  if (type === 'screen') {
    return [
      {
        label: 'Nepřesnost v metrikách',
        value: 'Nepřesnost v metrikách',
      },
      {
        label: 'Data mají zpoždění',
        value: 'Data mají zpoždění',
      },
      {
        label: 'Funkce nefunguje',
        value: 'Funkce nefunguje',
      },
      {
        label: 'Jiný problém',
        value: 'Jiný problém',
      },
    ];
  } else {
    return [
      {
        label: 'Špatný čas videa',
        value: 'Špatný čas videa',
      },
      {
        label: 'Špatný hráč',
        value: 'Špatný hráč',
      },
      {
        label: 'Chybná událost',
        value: 'Chybná událost',
      },
      {
        label: 'Video nefunguje',
        value: 'Video nefunguje',
      },
      {
        label: 'Jiný problém',
        value: 'Jiný problém',
      },
    ];
  }
};

export const getLabelByValue = (filters: IFilter[], value: string) => {
  return filters.find(item => item.value === value);
};
