import { useEffect, useRef, useState } from 'react';

import FiltersContainer from '../03-Partials/FiltersContainer';
import IceTimeRow from '../03-Partials/IceTimeRow';
import IceTimeTimeline from '../03-Partials/IceTimeTimeline';
import FiltersRow from '../04-Micros/FiltersRow';
import Loading from '../04-Micros/Loading';
import Switch from '../04-Micros/Switch';
import { useGetHeadToHead } from '../../Hooks';
import { useAppSelector } from '../../Redux/store';
import { IIceTimeOptions, IIceTimePlayer, IIceTimeSort } from '../../Types';
import {
  comparePlayersByLineup,
  getPlayerToiInGameType,
  getTeamFilters,
  getTeamGoals,
  getThirdsFilterFromTime,
  getThirdsFilters,
  getThirdsInterval,
  isInTime,
} from '../../Utils';

const IceTime = () => {
  const match = useAppSelector(state => state.match.value);

  const [sortBy, setSortBy] = useState<IIceTimeSort>('lineup');
  const [hasScrollbar, setHasScrollBar] = useState<boolean>(false);
  const [teamActive, setTeamActive] = useState<string>('');
  const [thirdsActive, setThirdsActive] = useState<string>(
    getThirdsFilterFromTime(match.length, getThirdsFilters(match.length, match.game, true)),
  );
  const [details, setDetails] = useState<boolean>(true);
  const [options, setOptions] = useState<IIceTimeOptions>({
    start: 0,
    end: 0,
    gameState: undefined,
  });

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { start, end } = getThirdsInterval(thirdsActive);
    setOptions(prevState => ({
      ...prevState,
      start: start,
      end: end,
    }));
  }, [thirdsActive]);

  useEffect(() => {
    if (tableRef.current) {
      setHasScrollBar(tableRef.current!.scrollHeight > window.innerHeight - 342);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef.current]);

  const handleDetails = () => {
    setDetails(prevState => !prevState);
  };

  const filteredGameState = (
    teamActive === match.homeTeam.shortcut ? match.homeTeam.gameState : match.awayTeam.gameState
  ).filter(state => isInTime(options.start!, options.end!, state.start + 1));

  const compareFunction = {
    lineup: comparePlayersByLineup,
    TOI: (a: IIceTimePlayer, b: IIceTimePlayer) => b.timeOnIce.toi - a.timeOnIce.toi,
    TOT: (a: IIceTimePlayer, b: IIceTimePlayer) =>
      (b.metrics ? b.metrics.TOI : 0) - (a.metrics ? a.metrics.TOI : 0),
    SH: (a: IIceTimePlayer, b: IIceTimePlayer) =>
      getPlayerToiInGameType(
        filteredGameState,
        b.timeOnIce.shifts
          ? b.timeOnIce.shifts.filter(shift =>
              isInTime(options.start!, options.end!, shift[0].from),
            )
          : [],
        'SH',
        options,
      ) -
      getPlayerToiInGameType(
        filteredGameState,
        a.timeOnIce.shifts
          ? a.timeOnIce.shifts.filter(shift =>
              isInTime(options.start!, options.end!, shift[0].from),
            )
          : [],
        'SH',
        options,
      ),
    PP: (a: IIceTimePlayer, b: IIceTimePlayer) =>
      getPlayerToiInGameType(
        filteredGameState,
        b.timeOnIce.shifts
          ? b.timeOnIce.shifts.filter(shift =>
              isInTime(options.start!, options.end!, shift[0].from),
            )
          : [],
        'PP',
        options,
      ) -
      getPlayerToiInGameType(
        filteredGameState,
        a.timeOnIce.shifts
          ? a.timeOnIce.shifts.filter(shift =>
              isInTime(options.start!, options.end!, shift[0].from),
            )
          : [],
        'PP',
        options,
      ),
  };

  const { home, away, isLoading } = useGetHeadToHead(match, options, compareFunction[sortBy]);

  return (
    <>
      <FiltersContainer>
        <FiltersRow filters={getTeamFilters(match)} active={teamActive} setActive={setTeamActive} />
        <FiltersRow
          filters={getThirdsFilters(match.length, match.game, true)}
          active={thirdsActive}
          setActive={setThirdsActive}
        />
        <Switch active={details} onClick={handleDetails} label='Podrobnosti' />
      </FiltersContainer>
      <main className='IceTime'>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <IceTimeTimeline
              gameStates={filteredGameState}
              hasScrollbar={hasScrollbar}
              setSortBy={setSortBy}
              sortBy={sortBy}
              thirdsActive={thirdsActive}
            />
            <div className='IceTimeTable' ref={tableRef}>
              {(teamActive === match.homeTeam.shortcut ? home : away).map(player => (
                <IceTimeRow
                  key={player.id}
                  player={player}
                  options={options}
                  gameStates={filteredGameState}
                  details={details}
                  teamGoals={getTeamGoals(
                    teamActive === match.homeTeam.shortcut
                      ? match.homeTeam.roster
                      : match.awayTeam.roster,
                    match.goal,
                  )}
                  opponentGoals={getTeamGoals(
                    teamActive === match.homeTeam.shortcut
                      ? match.awayTeam.roster
                      : match.homeTeam.roster,
                    match.goal,
                  )}
                />
              ))}
            </div>
            <IceTimeTimeline hasScrollbar={hasScrollbar} thirdsActive={thirdsActive} />
          </>
        )}
      </main>
    </>
  );
};

export default IceTime;
